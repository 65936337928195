export const SALES_RETURN = {
    BASE_PATH: '/sales-return/',
    BASE_PATH_MAKE_PAYMENT: '/sales-return/make-payment/',
    MAKE_PAYMENT_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Sales Return',
    LIST_PAGE_TITLE: 'Sales Return List',
    EDIT_PAGE_TITLE: 'Edit Sales Return',
    GET_ALL: 'sale-return',
    GET_ALL_MAKE_PAYMENTS: 'sale-return/payment',
    GET_ONE: 'sale-return/edit/',
    CREATE: 'sale-return/create',
    UPDATE: 'sale-return/update/',
    DELETE: 'sale-return/delete/',
    DELETE_MAKE_PAYMENT: 'sale-return/payment/delete/',
    MAKE_PAYMENT: 'sale-return/payment/',
    ADD_NEW_TITLE: 'Add New Sale Return',
};
