import { z } from 'zod';

export const supplierSchema = z.object({
    supplier_code: z.string().optional(),
    supplier_name: z.string().min(1, {
        message: 'Supplier Name is required',
    }),
    supplier_phone1: z.string().min(1),
    supplier_email: z.string().nullable().optional(),
    supplier_address: z.string().min(1, { message: "Required" }),
    // township_name: z.any().nullable().optional(),
    country_id: z.union([z.string().min(1, { message: "Required" }), z.number().min(1, { message: "Required" })]),
    state_id: z.union([z.string().min(1, { message: "Required" }), z.number().min(1, { message: "Required" })]),
    city_id: z.union([z.string().min(1, { message: "Required" }), z.number().min(1, { message: "Required" })]),
    // state_name: z.any().nullable().optional(),
    contact_first_name: z.string().nullable().optional(),
    contact_last_name: z.string().nullable().optional(),
    contact_email: z.string().nullable().optional(),
    contact_phoneno: z.string(),
    remark: z.string().optional(),
    description: z.string().optional(),
});

export type ZSupplier = z.infer<typeof supplierSchema>;

export interface TSupplier extends ZSupplier {
    supplier_id: string | number;
}
