export const getBaseUrl = () =>
  import.meta.env.VITE_NODE_ENV === "production"
    ? import.meta.env.VITE_PROD_API
    : import.meta.env.VITE_NODE_ENV === "staging"
    ? import.meta.env.VITE_STAGING_API
    : import.meta.env.VITE_DEV_API;

export const getUploadUrl = () =>
  import.meta.env.VITE_NODE_ENV === "production"
    ? import.meta.env.VITE_PROD_UPLOAD_PATH
    : import.meta.env.VITE_NODE_ENV === "staging"
    ? import.meta.env.VITE_STAGING_UPLOAD_PATH
    : import.meta.env.VITE_DEV_UPLOAD_PATH;
