import CustomForm from '@components/form/CustomForm';
import PageWrapper from '@components/layouts/PageWrapper';
import { useCallback, useMemo } from 'react';
import { supplierFields } from '@helpers/fields/supplier/supplier.fields';
import { useNavigate } from 'react-router-dom';
import { useCreateSupplierMutation, useGetNewSupplierCodeQuery } from '@states/supplier/supplier.api';
import { useErrorHandling } from '@hooks/useErrorHandling';
import { castTypeArr } from '@utils/general/cast-type';
import { TFailResponse } from '@type-defs/general/TFailResponse';
import { removeKeys } from '@utils/general/removeKeys';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';
import { Toast } from '@helpers/popups/Toast';
import { SUPPLIER } from '@utils/constants/supplier.constant';
import { useGetAllCountriesQuery } from '@states/common/common.api';
import {
    addCities,
    addStates,
    addCountries,
} from '@states/common/common.slice';

import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { supplierSchema } from '@helpers/validation-schemas/supplier/supplier.schema';
import { LoadingSpinner } from '@components/layouts/LoadingSpinner';
import useLocationEffect from "@/hooks/shop/useLocationEffect";

const SupplierAddPage = () => {
    const navigate = useNavigate();
    const {
        cities,
        states,
        isLoading: locationLoading,
    } = useLocationEffect();

    const [createSupplier, { isLoading, isSuccess, error: createError }] =
        useCreateSupplierMutation();
    
    const { data: countryData } = useGetAllCountriesQuery();

    const { data: supplierCode } = useGetNewSupplierCodeQuery();

    const {
        states: storedStates,
        cities: storedCities,
        countries: storedCountries,
    } = useSelector((state: RootState) => state.common);

    useErrorHandling(...castTypeArr<TFailResponse>([createError]));

    const fields = useMemo(() => {
        return supplierFields({
            // stored data
            stateData: states,
            stateSelectAction: addStates,
            cities,
            citySelectAction: addCities,
            countries: countryData,
            countrySelectAction: addCountries,
            // disabled
            cityDisabled: storedStates.length <= 0,
            stateDisabled: storedCountries.length <= 0,
            // selector ids
            divisionSelectId: storedStates[0]?.state_id,
            citySelectId: storedCities[0]?.city_id,
            countrySelectId: storedCountries[0]?.township_id,
            supplierCodeValue: supplierCode?.data
        });
    }, [
        countryData,
        cities,
        states,
        storedCountries,
    ]);

    const asyncDispatcher = useCallback(
        async (reqBody: any) => {
            try {
                const rest = removeKeys(
                    reqBody,
                    'state_id',
                    'state_name',
                    'city_id',
                    'city_name',
                    'country_id',
                    'township_name'
                );

                const res = await createSupplier({
                    ...rest,
                    supplier_division_id: reqBody.state_id,
                    supplier_city_id: reqBody.city_id,
                    supplier_country_id: reqBody.country_id,
                });

                const successData = 'data' in res ? res.data : null;
                const { success, message } = successData as TSuccessResponse;

                if (success || isSuccess) {
                    await Toast.fire({
                        title: message,
                        icon: 'success',
                    });

                    navigate(`${SUPPLIER.BASE_PATH}`, {
                        state: {
                            reload: true,
                        },
                    });
                }
            } catch (error) {
                console.log('Error', error);
            }
        },
        [createSupplier, isSuccess, Toast, navigate]
    );

    return (
        <PageWrapper>
            {isLoading && <LoadingSpinner />}
            <CustomForm
                title="Add New Supplier"
                schema={supplierSchema}
                fields={fields}
                asyncDispatcher={asyncDispatcher}
            />
        </PageWrapper>
    );
};

export default SupplierAddPage;
