import { modules, subModules, actions, moduleMatchIds, subModulesMatchIds } from "@config/app-modules";
import AuthPermission from "../types/general/TAuthPermission";
import { useCallback } from "react";

type ModuleType = (typeof subModules[keyof typeof subModules]) | typeof modules.DASHBOARD;
type ActionType = typeof actions[keyof typeof actions];

export function useHasPermission(module: ModuleType, action: ActionType): boolean {
    const hasPermission = useCallback((module: ModuleType, action: ActionType) => {
        const storedPermissions = localStorage.getItem("dms-user-permissions");
        let hasPermission = false;

        if (!storedPermissions) {
            throw new Error("User permission not exist");
        }

        const userPermissions = JSON.parse(storedPermissions) as AuthPermission[];

        for (let i = 0; i < userPermissions.length; i++) {
            const userPermission = userPermissions[i];

            // For dashboard
            if (module === modules.DASHBOARD) {
                if (
                    userPermission.module_match_id === moduleMatchIds[modules.DASHBOARD] && 
                    userPermission.actions.includes(action)
                ) {
                    hasPermission = true;
                    break;
                }
            }

            // For other sub modules
            else if (
                userPermission.sub_module_match_id === subModulesMatchIds[module] && 
                userPermission.actions.includes(action)
            ) {
                hasPermission = true;
                break;
            }
        }

        return hasPermission;
    }, []);

    return hasPermission(module, action);
}