export const RETAIL_SALE = {
  BASE_PATH: "/retail-sale/retail-sale",
  HISTORY: "/retail-sale/retail-sale-order-history",
  ADD_PATH() {
    return this.BASE_PATH + "/add";
  },
  ADD_BUTTON_TITLE: "Add New Retail Sale",
  LIST_PAGE_TITLE: "Retail Sale Order History",
  EDIT_PAGE_TITLE: "Edit Retail Sale",
  GET_ALL: "retail-sale-orders/",
  GET_ONE: "retail-sale-orders/",
  CREATE: "retail-sale-orders/",
  UPDATE: "retail-sale-orders/",
  DELETE: "retail-sale-orders/",
  ADD_NEW_TITLE: "Add New Retail Sale",
};
