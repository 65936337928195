import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { WHOLE_SALE_ORDER } from '@/utils/constants/whole-sale-order.constant';
import { baseQuery, baseQueryWithReauth } from '@states/auth/auth.api';

export const secondarySaleOrderApi = createApi({
    reducerPath: 'secondarySaleOrderApi',
    tagTypes: ['SecondarySaleOrder'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSecondarySaleOrders: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_name?: string;
                whole_sales_order_code?: string;
                customer_id?: string | number;
                payment_type_id?: string | number;
                from_date?: Date | string;
                to_date?: Date | string;
                status?: string | number;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_name,
                whole_sales_order_code,
                customer_id,
                payment_type_id,
                from_date,
                to_date,
                status,
            }) => {
                const queryParams = {
                    url: WHOLE_SALE_ORDER.GET_ALL,
                    params: { page, limit },
                };

                if (customer_name) {
                    //@ts-ignore
                    queryParams.params.customer_name = customer_name;
                }

                if (whole_sales_order_code) {
                    //@ts-ignore
                    queryParams.params.whole_sales_order_code =
                        whole_sales_order_code;
                }

                if (customer_id) {
                    //@ts-ignore
                    queryParams.params.customer_id = customer_id;
                }

                if (payment_type_id) {
                    //@ts-ignore
                    queryParams.params.payment_type_id = payment_type_id;
                }

                if (from_date) {
                    //@ts-ignore
                    queryParams.params.from_date = from_date;
                }

                if (to_date) {
                    //@ts-ignore
                    queryParams.params.to_date = to_date;
                }

                if (status) {
                    //@ts-ignore
                    queryParams.params.status = status;
                }

                return queryParams;
            },
        }),
        getSecondarySaleOrder: builder.query<
            any,
            { whole_sales_order_id: string | number }
        >({
            query: ({ whole_sales_order_id }) =>
                `${WHOLE_SALE_ORDER.GET_ONE}${whole_sales_order_id}`,
        }),
        createSecondarySaleOrder: builder.mutation({
            query: (body) => ({
                url: WHOLE_SALE_ORDER.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateSecondarySaleOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WHOLE_SALE_ORDER.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        approveSecondaryOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WHOLE_SALE_ORDER.APPROVE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteSecondarySaleOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WHOLE_SALE_ORDER.DELETE}${id}`,
                method: 'DELETE',
                body
            }),
        }),
        getNewWholeSaleCode: builder.query<Record<string, any>, void>({
            query: () => `${WHOLE_SALE_ORDER.GET_ALL}/new-code`
        })
    }),
});

export const {
    useGetAllSecondarySaleOrdersQuery,
    useGetSecondarySaleOrderQuery,
    useApproveSecondaryOrderMutation,
    useCreateSecondarySaleOrderMutation,
    useUpdateSecondarySaleOrderMutation,
    useDeleteSecondarySaleOrderMutation,
    useGetNewWholeSaleCodeQuery
} = secondarySaleOrderApi;
