import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { SHOP_CATEGORY } from "@/utils/constants/shop-management/shop-category.constant";
import { baseQueryWithReauth } from "../auth/auth.api";
export const shopCategoryApi = createApi({
    reducerPath: "shopCategoryApi",
    tagTypes: ["ShopCategory"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({

        getAllShopCategories: builder.query<
            [],
            { page?: number; limit?: number; search_key?: string }
        >({
            query: ({ page = 1, limit = 10, search_key }) => {
                const queryParams = {
                    url: "/shop-categories",
                    params: { page, limit },
                };

                if (search_key) {
                    //@ts-ignore
                    queryParams.params.search_key = search_key;
                }

                return queryParams;
            },
        }),

        createShopCategory: builder.mutation({
            query: (body) => ({
                url: "/shop-categories",
                method: "POST",
                body,
            }),
        }),

        updateShopCategory: builder.mutation({
            query: ({ id, body }) => ({
                url: `/shop-categories/${id}`,
                method: "PATCH",
                body,
            }),
        }),

        deleteShopCategory: builder.mutation({
            query: (id) => ({
                url: `/shop-categories/${id}`,
                method: "DELETE",
            }),
        }),

        getNewShopCategoryCode: builder.query<Record<string, any>, void>({
            query: () => "/shop-categories/new-code"
        })
    }),
    keepUnusedDataFor: 0
});

export const {
    useGetAllShopCategoriesQuery,
    useCreateShopCategoryMutation,
    useUpdateShopCategoryMutation,
    useDeleteShopCategoryMutation,
    useGetNewShopCategoryCodeQuery
} = shopCategoryApi;
