export const WHOLE_PAYMENT_RECEIVE = {
    BASE_PATH: '/whole-sale-order/payment-receive/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    LIST_PAGE_TITLE: 'Whole Sales Order Payment Receive List',
    EDIT_PAGE_TITLE: 'Edit Whole Sales Order Payment Receive',
    GET_ALL: 'whole-sales/payment-receive',
    GET_ONE: 'whole-sales/payment-receive/edit/',
    CREATE: 'whole-sales/payment-receive/create',
    UPDATE: 'whole-sales/payment-receive/update/',
    DELETE: 'whole-sales/payment-receive/delete/',
};
