import React from 'react';
import Select from 'react-select';
import { FieldErrors, Controller, Control } from 'react-hook-form';
import { isNumeric } from '../../utils/general/isNumeric';
import CreatableSelect from 'react-select/creatable';

interface CustomSelectFieldProps {
    name: string;
    options: any[] | undefined;
    label?: string;
    value?: any;
    noLabel?: boolean;
    defaultValue?: any;
    control: Control;
    errors: FieldErrors<any>;
    disabled?: boolean;
    colSpan?: number;
    required?: boolean;
    classNames?: string;
    handleChange?: (value: any) => void;
}

const CustomeSelectCreateField: React.FC<CustomSelectFieldProps> = ({
    name,
    options,
    label,
    value,
    noLabel,
    defaultValue,
    control,
    errors,
    disabled,
    colSpan,
    required,
    classNames,
    handleChange,
}) => {
    const targetOption = options?.find((option) => option.value == value);
    let newOption = null;

    if (!options) {
        return (
            <div
                style={{
                    gridColumn:
                        colSpan !== 6
                            ? `auto / span ${colSpan || '2'}`
                            : '1 / -1',
                }}
                className={`flex flex-col p-2 ${classNames}`}
            >
                <label
                    className="mb-2 text-gray-700"
                    style={{ textTransform: 'capitalize' }}
                    htmlFor="name"
                >
                    {label || name}
                </label>
                <CreatableSelect
                    isDisabled={disabled}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            paddingTop: '0.19rem',
                            paddingBottom: '0.19rem',
                            borderColor: 'rgb(128,149,187)',
                            borderWidth: '0.5px',
                        }),
                        option: (
                            base,
                            { data, isDisabled, isFocused, isSelected }
                        ) => ({
                            ...base,
                            backgroundColor: isSelected
                                ? 'rgb(45,120,219)'
                                : isFocused
                                ? '#e5e7eb'
                                : '',
                            color: isSelected ? '#ffffff' : '',
                        }),
                    }}
                    options={[]}
                />
            </div>
        );
    }

    return (
        <div
            style={{
                gridColumn:
                    colSpan !== 6 ? `auto / span ${colSpan || '2'}` : '1 / -1',
            }}
            className={`flex flex-col p-2 ${classNames}`}
        >
            {!noLabel ? (
                <label
                    className="mb-2 text-gray-700"
                    style={{ textTransform: 'capitalize' }}
                    htmlFor="name"
                >
                    {label || name}
                    {required ? (
                        <span className="ml-[0.15rem] text-red-600 text-md">
                            &#42;
                        </span>
                    ) : null}
                </label>
            ) : null}

            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CreatableSelect
                        isDisabled={disabled}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                paddingTop: '0.19rem',
                                paddingBottom: '0.19rem',
                                borderColor: 'rgb(128,149,187)',
                                borderWidth: '0.5px',
                            }),
                            option: (
                                base,
                                { data, isDisabled, isFocused, isSelected }
                            ) => ({
                                ...base,
                                backgroundColor: isSelected
                                    ? 'rgb(45,120,219)'
                                    : isFocused
                                    ? '#e5e7eb'
                                    : '',
                                color: isSelected ? '#ffffff' : '',
                            }),
                        }}
                        defaultValue={targetOption}
                        // defaultValue={defaultValue ? defaultValue : undefined}
                        inputId={name}
                        // name={name}
                        value={options?.find((option) => option.value == value)}
                        onBlur={onBlur}
                        onChange={(val) => {
                            if (isNumeric(val.value)) {
                                val.value = parseInt(val.value);
                            }

                            if (val?.__isNew__) {
                            }

                            //@ts-ignore
                            val.value && handleChange(val.value);

                            onChange(val.value);
                        }}
                        options={options}
                    />
                )}
            />
            {(
                <div className="mt-2 font-[300] italic text-red-600">
                    {/* @ts-ignore */}
                    {errors[name]?.message}
                </div>
            )}
        </div>
    );
};

export default CustomeSelectCreateField;

// import CreatableSelect from 'react-select/creatable';

// const CustomeSelectCreateField = () => {
//     const handleChange = (value: any) => {
//         // console.log(value);
//     };

//     const handleInputChange = (value: any) => {
//         console.log(value, 'okay');
//     };

//     return (
//         <CreatableSelect
//             options={[
//                 {
//                     label: 'Option 1',
//                     value: 'option-1',
//                 },
//                 {
//                     label: 'Option 2',
//                     value: 'option-2',
//                 },
//             ]}
//             // isClearable
//             // isMulti
//             // components={{ DropdownIndicator: null }}
//             // inputValue={tagInputValue}
//             // menuIsOpen={false}
//             onChange={handleChange}
//             placeholder="Type an employee and press enter"
//             // onKeyDown={handleKeyDown}
//             onInputChange={handleInputChange}
//             // value={tagValue}
//         />
//     );
// };

// export default CustomeSelectCreateField;
