import CustomerIcon from "@components/icons/menu/CustomerIcon";
import DirectSalesReturnIcon from "@components/icons/menu/DirectSalesReturnIcon";
import PrimarySalesOrderIcon from "@components/icons/menu/PrimarySalesOrderIcon";
import PrimarySalesReturnIcon from "@components/icons/menu/PrimarySalesReturnIcon";
import ProductIcon from "@components/icons/menu/ProductIcon";
import PurchaseIcon from "@components/icons/menu/PurchaseIcon";
import UnitIcon from "@components/icons/menu/UnitIcon";
import UserManagementIcon from "@components/icons/menu/UserManagementIcon";
import WarehouseAndInventoryIcon from "@components/icons/menu/WarehouseAndInventoryIcon";

import { modules, subModules, moduleMatchIds, subModulesMatchIds } from "@config/app-modules";

const mainModules = [
    {
        id: 1,
        match_id: moduleMatchIds[modules.SHOP_MANAGEMENT],
        name: "Shop Management",
        path: "/shop-management",
        subModules: [
            {
                id: 211,
                match_id: subModulesMatchIds[subModules.SHOP_CATEGORY],
                name: "Shop Category",
                path: "/shop-category",
            },
            {
                id: 222,
                match_id: subModulesMatchIds[subModules.SHOP],
                name: "Shop",
                path: "/shop",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <UserManagementIcon isRouted={isRouted} />;
        },
    },
    {
        id: 2,
        match_id: moduleMatchIds[modules.USER_MANAGEMENT],
        name: "User Management",
        path: "/user-management",
        subModules: [
            {
                id: 11,
                match_id: subModulesMatchIds[subModules.USER],
                name: "User",
                path: "/office-user",
            },
            {
                id: 1111,
                match_id: subModulesMatchIds[subModules.USER_ROLE],
                name: "User Role",
                path: "/user-roles",
            },
            {
                id: 12,
                match_id: subModulesMatchIds[subModules.SHOP_USER_ASSIGN],
                name: "Shop User Assign",
                path: "/shop-user-assign",
            },
            {
                id: 13,
                match_id: subModulesMatchIds[subModules.USER_ROLE_ASSIGN],
                name: "User Role Assign",
                path: "/user-role-assign",
            }
        ],
        renderIcon: (isRouted: boolean) => {
            return <UserManagementIcon isRouted={isRouted} />;
        },
    },
    {
        id: 3,
        match_id: moduleMatchIds[modules.UNIT],
        name: "Unit",
        path: "/unit",
        subModules: [
            {
                id: 31,
                match_id: subModulesMatchIds[subModules.UNIT_OF_CONVERSION],
                name: "Unit of Conversion",
                path: "/unit-of-conversion",
            },
            {
                id: 32,
                match_id: subModulesMatchIds[subModules.UNIT_OF_MEASUREMENT],
                name: "Unit of Measurement",
                path: "/unit-of-measurement",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <UnitIcon isRouted={isRouted} />;
        },
    },
    {
        id: 4,
        match_id: moduleMatchIds[modules.PRODUCT],
        name: "Product",
        path: "/product",
        subModules: [
            {
                id: 21,
                match_id: subModulesMatchIds[subModules.PRODUCT_INFORMATION],
                name: "Product Information",
                path: "/product-information",
            },
            {
                id: 24,
                match_id: subModulesMatchIds[subModules.BRAND],
                name: "Brand",
                path: "/brand",
            },
            {
                id: 22,
                match_id: subModulesMatchIds[subModules.PRODUCT_CATEGORY],
                name: "Product Category",
                path: "/product-category",
            },
            {
                id: 23,
                match_id: subModulesMatchIds[subModules.PRODUCT_CATEGORY_ASSIGN],
                name: "Product Category Assign",
                path: "/product-category-assign",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <ProductIcon isRouted={isRouted} />;
        },
    },
    {
        id: 5,
        match_id: moduleMatchIds[modules.WAREHOUSE_INVENTORY],
        name: "Warehouse & Inventory",
        path: "/warehouse-and-inventory",
        subModules: [
            {
                id: 61,
                match_id: subModulesMatchIds[subModules.WAREHOUSE],
                name: "Warehouse",
                path: "/warehouse",
            },
            {
                id: 63,
                match_id: subModulesMatchIds[subModules.STOCK_IMPORT],
                name: "Stock Import",
                path: "/stock-import",
            },
            {
                id: 64,
                match_id: subModulesMatchIds[subModules.STOCK_TRANSFER],
                name: "Stock Transfer",
                path: "/stock-transfer",
            },
            {
                id: 65,
                match_id: subModulesMatchIds[subModules.STOCK_BALANCE],
                name: "Stock Balance",
                path: "/stock-balance",
            },
            {
                id: 66,
                match_id: subModulesMatchIds[subModules.STOCK_ADJUSTMENT],
                name: "Stock Adjustment",
                path: "/stock-adjustment",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <WarehouseAndInventoryIcon isRouted={isRouted} />;
        },
    },
    {
        id: 6,
        match_id: moduleMatchIds[modules.CUSTOMER],
        name: "Customer",
        path: "/customer",
        subModules: [
            {
                id: 41,
                match_id: subModulesMatchIds[subModules.CUSTOMER_CATEGORY],
                name: "Customer Category",
                path: "/customer-category",
            },
            {
                id: 42,
                match_id: subModulesMatchIds[subModules.CUSTOMER],
                name: "Customer",
                path: "/customer",
            }
        ],
        renderIcon: (isRouted: boolean) => {
            return <CustomerIcon isRouted={isRouted} />;
        },
    },
    {
        id: 7,
        match_id: moduleMatchIds[modules.PURCHASE],
        name: "Purchase",
        path: "/purchase",
        subModules: [
            {
                id: 101,
                match_id: subModulesMatchIds[subModules.SUPPLIER],
                name: "Supplier",
                path: "/supplier",
            },
            {
                id: 123,
                match_id: subModulesMatchIds[subModules.PURCHASE_ORDER],
                name: "Purchase Order",
                path: "/purchase-order",
            },
            {
                id: 141,
                match_id: subModulesMatchIds[subModules.BILL],
                name: "Bill",
                path: "/bill",
            },
            {
                id: 142,
                match_id: subModulesMatchIds[subModules.PAYMENT_MADE],
                name: "Payment Made",
                path: "/payment-made",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PurchaseIcon isRouted={isRouted} />;
        },
    },
    {
        id: 8,
        match_id: moduleMatchIds[modules.PURCHASE_RETURN],
        name: "Purchase Return",
        path: "/purchase-return",
        subModules: [
            {
                id: 231,
                match_id: subModulesMatchIds[subModules.PURCHASE_RETURN],
                name: "Purchase Return",
                path: "/purchase-return",
            },
            {
                id: 232,
                match_id: subModulesMatchIds[subModules.PURCHASE_RETURN_PAYMENT_RECEIVE],
                name: "Payment Receive",
                path: "/payment-receive",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PrimarySalesReturnIcon isRouted={isRouted} />;
        },
    },
    {
        id: 9,
        match_id: moduleMatchIds[modules.RETAIL_SALE],
        name: "Retail Sale",
        path: "/retail-sale",
        subModules: [
            {
                id: 231,
                match_id: subModulesMatchIds[subModules.RETAIL_SALE_ORDER],
                name: "New Retail Sale",
                path: "/retail-sale/add",
            },
            {
                id: 232,
                match_id: subModulesMatchIds[subModules.RETAIL_SALE_ORDER_HISTORY],
                name: "Retail Sale Order History",
                path: "/retail-sale-order-history",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PrimarySalesReturnIcon isRouted={isRouted} />;
        },
    },
    {
        id: 10,
        match_id: moduleMatchIds[modules.WHOLE_SALE],
        name: "Whole Sale",
        path: "/whole-sale-order",
        subModules: [
            {
                id: 71,
                match_id: subModulesMatchIds[subModules.WHOLE_SALE],
                name: "Wholesale",
                path: "/sale-order",
            },
            {
                id: 73,
                match_id: subModulesMatchIds[subModules.INVOICE],
                name: "Invoice",
                path: "/invoice",
            },
            {
                id: 74,
                match_id: subModulesMatchIds[subModules.WHOLE_SALE_PAYMENT_RECEIVE],
                name: "Payment Receive",
                path: "/payment-receive",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PrimarySalesOrderIcon isRouted={isRouted} />;
        },
    },
    {
        id: 11,
        match_id: moduleMatchIds[modules.SALE_RETURN],
        name: "Sales Return",
        path: "/sales-return",
        subModules: [
            {
                id: 201,
                match_id: subModulesMatchIds[subModules.SALE_RETURN],
                name: "Sales Return",
                path: "/",
            },
            {
                id: 202,
                match_id: subModulesMatchIds[subModules.MAKE_PAYMENT],
                name: "Make Payment",
                path: "/make-payment",
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <DirectSalesReturnIcon isRouted={isRouted} />;
        },
    },
];

export type mainModuleType = typeof mainModules;

export default mainModules;
