import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { SHOP_USER_ASSIGN } from '@utils/constants/shop-user-assign.constant';
import { baseQueryWithReauth } from '../auth/auth.api';

export const shopUserAssignApi = createApi({
    reducerPath: 'shopUserAssign',
    tagTypes: ['ShopUserAssign'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllShopUserAssigns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                user_name?: string;
                shop_id?: number;
            }
        >({
            query: ({ page = 1, limit = 10, user_name, shop_id }) => {
                const queryParams = {
                    url: "/shop-user-assigns",
                    params: { page, limit },
                };

                if (user_name) {
                    //@ts-ignore
                    queryParams.params.user_name = user_name;
                }
                if (shop_id) {
                    //@ts-ignore
                    queryParams.params.shop_id = shop_id;
                }

                return queryParams;
            },
        }),
        // getWayUserAssign: builder.query<any, { user_id: string | number }>({
        //     query: ({ user_id }) => WAY_USER_ASSIGN.GET_ONE + user_id,
        // }),
        // getWayUserAssignDetail: builder.query<
        //     any,
        //     { user_id: string | number }
        // >({
        //     query: ({ user_id }) => WAY_USER_ASSIGN.GET_DETAIL + user_id,
        // }),
        createShopUserAssign: builder.mutation({
            query: (body) => ({
                url: "/shop-user-assigns",
                method: 'POST',
                body,
            }),
        }),
        updateShopUserAssign: builder.mutation({
            query: ({ userId, body }) => ({
                url: `/shop-user-assigns/${userId}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteShopUserAssign: builder.mutation({
            query: (userId) => ({
                url: `/shop-user-assigns/${userId}`,
                method: 'DELETE'
            }),
        }),
    }),
});

export const {
    useGetAllShopUserAssignsQuery,
    useDeleteShopUserAssignMutation,
    useCreateShopUserAssignMutation,
    useUpdateShopUserAssignMutation
} = shopUserAssignApi;
