import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { BRAND } from '../../utils/constants/brand.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const brandApi = createApi({
    reducerPath: 'brandApi',
    tagTypes: ['Brand'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllBrands: builder.query<
            [],
            { page?: number; limit?: number; brand_name?: string, product_category_id?: number }
        >({
            query: ({ page = 1, limit = 10, brand_name, product_category_id }) => {
                const queryParams = {
                    url: BRAND.GET_ALL,
                    params: { page, limit },
                };

                if (brand_name) {
                    //@ts-ignore
                    queryParams.params.brand_name = brand_name;
                }

                if (product_category_id) {
                    //@ts-ignore
                    queryParams.params.product_category_id = product_category_id;
                }

                return queryParams;
            },
        }),
        createBrand: builder.mutation({
            query: (body) => ({
                url: BRAND.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateBrand: builder.mutation({
            query: ({ id, body }) => ({
                url: `${BRAND.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteBrand: builder.mutation({
            query: (id) => ({
                url: `${BRAND.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
        getNewBrandCode: builder.query<Record<string, any>, void>({
            query: () => `${BRAND.GET_ALL}/new-code`
        })
    }),
    keepUnusedDataFor: 0,
});

export const {
    useGetAllBrandsQuery,
    useCreateBrandMutation,
    useUpdateBrandMutation,
    useDeleteBrandMutation,
    useGetNewBrandCodeQuery
} = brandApi;
