import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { PRODUCT_CATEGORY_ASSIGN } from "@/utils/constants/product-category-assign.constants";
import { baseQueryWithReauth } from "../auth/auth.api";

export const productCategoryAssignApi = createApi({
  reducerPath: "productCategoryAssignApi",
  tagTypes: ["ProductCategoryAssign"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllProductCategoryAssigns: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        warehouse_id?: number,
        product_category_id?: number
      }
    >({
      query: ({ page = 1, limit = 10, warehouse_id, product_category_id }) => {
        const queryParams = {
          url: PRODUCT_CATEGORY_ASSIGN.GET_ALL,
          params: { page, limit, warehouse_id, product_category_id },
        };

        if (warehouse_id) {
          queryParams.params.warehouse_id = warehouse_id;
        }

        if (product_category_id) {
          queryParams.params.product_category_id = product_category_id;
        }

        return queryParams;
      },
    }),
    createProductCategoryAssign: builder.mutation({
      query: (body) => ({
        url: PRODUCT_CATEGORY_ASSIGN.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateProductCategoryAssign: builder.mutation({
      query: ({ warehouseId, body }) => ({
        url: `${PRODUCT_CATEGORY_ASSIGN.UPDATE}${warehouseId}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteProductCategoryAssign: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_CATEGORY_ASSIGN.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
    getProductCategoryAssignByWarehouseId: builder.mutation({
      query: (warehouseId) => ({
        url: `${PRODUCT_CATEGORY_ASSIGN.GET_ALL}${warehouseId}`,
        method: "GET"
      })
    })
  }),
});

export const {
  useGetAllProductCategoryAssignsQuery,
  useCreateProductCategoryAssignMutation,
  useUpdateProductCategoryAssignMutation,
  useDeleteProductCategoryAssignMutation,
  useGetProductCategoryAssignByWarehouseIdMutation
} = productCategoryAssignApi;
