import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { logOut, setCredentials } from './auth.slice';

export const baseQuery = fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('dms-access-token');

        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        
        return headers;
    },
});

export const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
        console.log('Sending refresh token...');

        const refreshToken = localStorage.getItem('dms-refresh-token');

        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh',
                method: 'POST',
                body: { refresh_token: refreshToken },
            },
            api,
            extraOptions
        );

        // @ts-ignore
        if (refreshResult?.data?.data) {
            // @ts-ignore
            const { access_token: accessToken, refresh_token: refreshToken } = refreshResult.data.data;

            api.dispatch(
                setCredentials({
                    accessToken,
                    refreshToken,
                })
            );

            // Retry original query
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logOut());
            alert("Session expired, please log in again.");  // Optional feedback for users
        }
    }

    return result;
};

export const authApi = createApi({
    reducerPath: 'authApi',
    tagTypes: ['Auth'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                url: 'auth/login',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useLoginMutation } = authApi;
