import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { UNIT_OF_CONVERSION } from '../../utils/constants/unit-of-conversion.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const unitOfConversionApi = createApi({
    reducerPath: 'unitOfConversionApi',
    tagTypes: ['UnitOfConversion'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllUnitOfConversions: builder.query<
            [],
            { page: number; limit: number, search_key?: string }
        >({
            query: ({ page = 1, limit = 10, search_key }) => {
                const queryParams = {
                    url: UNIT_OF_CONVERSION.GET_ALL,
                    params: { page, limit, status: 1, search_key },
                };

                if (search_key) {
                    queryParams.params.search_key = search_key;
                }

                return queryParams;
            },
        }),
        getUnitOfConversion: builder.query<any, { id: string | number }>({
            query: ({ id }) => UNIT_OF_CONVERSION.EDIT + id,
        }),

        getBaseUnitOfConversion: builder.query<any, { id: string | number }>({
            query: ({ id }) => UNIT_OF_CONVERSION.GET_BASE_UNIT + id,
        }),
        createUnitOfConversion: builder.mutation({
            query: (body) => ({
                url: UNIT_OF_CONVERSION.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateUnitOfConversion: builder.mutation({
            query: ({ id, body }) => ({
                url: `${UNIT_OF_CONVERSION.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteUnitOfConversion: builder.mutation({
            query: (id) => ({
                url: `${UNIT_OF_CONVERSION.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0
});

export const {
    useGetAllUnitOfConversionsQuery,
    useCreateUnitOfConversionMutation,
    useGetUnitOfConversionQuery,
    useLazyGetUnitOfConversionQuery,
    useLazyGetBaseUnitOfConversionQuery,
    useUpdateUnitOfConversionMutation,
    useDeleteUnitOfConversionMutation,
} = unitOfConversionApi;
