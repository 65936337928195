import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { CUSTOMER_CATEGORY } from '../../utils/constants/customer-category.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const customerCategoryApi = createApi({
    reducerPath: 'customerCategoryApi',
    tagTypes: ['CustomerCategory'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllCustomerCategories: builder.query<
            [],
            { page?: number; limit?: number; customer_category_name?: string }
        >({
            query: ({ page = 1, limit = 10, customer_category_name }) => {
                const queryParams = {
                    url: CUSTOMER_CATEGORY.GET_ALL,
                    params: { page, limit },
                };

                if (customer_category_name) {
                    //@ts-ignore
                    queryParams.params.customer_category_name =
                        customer_category_name;
                }

                return queryParams;
            },
        }),
        createCustomerCategory: builder.mutation({
            query: (body) => ({
                url: CUSTOMER_CATEGORY.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateCustomerCategory: builder.mutation({
            query: ({ id, body }) => ({
                url: `${CUSTOMER_CATEGORY.UPDATE}/${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteCustomerCategory: builder.mutation({
            query: (id) => ({
                url: `${CUSTOMER_CATEGORY.DELETE}/delete/${id}`,
                method: 'DELETE',
            }),
        }),
        getNewCustomerCategoryCode: builder.query<Record<string, any>, void>({
            query: () => `${CUSTOMER_CATEGORY.GET_ALL}/new-code`
        })
    }),
});

export const {
    useGetAllCustomerCategoriesQuery,
    useCreateCustomerCategoryMutation,
    useUpdateCustomerCategoryMutation,
    useDeleteCustomerCategoryMutation,
    useGetNewCustomerCategoryCodeQuery
} = customerCategoryApi;
