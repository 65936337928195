import CustomForm from '@components/form/CustomForm';
import PageWrapper from '@components/layouts/PageWrapper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { supplierFields } from '@helpers/fields/supplier/supplier.fields';
import { useUpdateSupplierMutation } from '@states/supplier/supplier.api';
import { useErrorHandling } from '@hooks/useErrorHandling';
import { castTypeArr } from '@utils/general/cast-type';
import { TFailResponse } from '@type-defs/general/TFailResponse';
import { removeKeys } from '@utils/general/removeKeys';
import { Toast } from '@helpers/popups/Toast';
import { SUPPLIER } from '@utils/constants/supplier.constant';
import { useGetAllCountriesQuery, useGetAllStatesQuery } from '@states/common/common.api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@states/store';
import {
    addCities,
    addStates,
    addCountries,
} from '@states/common/common.slice';
import { supplierSchema } from '@helpers/validation-schemas/supplier/supplier.schema';
import { LoadingSpinner } from '@components/layouts/LoadingSpinner';
import useLocationEffect from "@/hooks/shop/useLocationEffect";

const SupplierEditPage = () => {
    const { id } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();

    const {
        states: storedStates,
        cities: storedCities,
        countries: storedCountries,
    } = useSelector((state: RootState) => state.common);

    const { data: countryData } = useGetAllCountriesQuery();

    const { supplier } = location.state as {
        supplier: any;
    };

    const {
        cities,
        states,
        isLoading: locationLoading,
    } = useLocationEffect();
    

    const shallowSupplier = useMemo(
        () => ({
            ...supplier,
            state_id: supplier.supplier_division_id,
            city_id: supplier.supplier_city_id,
            country_id: supplier.supplier_country_id,
        }),
        [supplier]
    );

    const [updateSupplier, { isLoading, error: updateError, isSuccess }] =
        useUpdateSupplierMutation();

    useErrorHandling(...castTypeArr<TFailResponse>([updateError]));
    
    const fields = useMemo(() => {
        return supplierFields({ 
            // stored data
            stateData: states,
            stateSelectAction: addStates,
            cities,
            citySelectAction: addCities,
            countries: countryData,
            countrySelectAction: addCountries,
            // disabled
            cityDisabled: storedStates.length <= 0 || Boolean(shallowSupplier.state_id),
            stateDisabled: storedCountries.length <= 0,
            // selector ids
            divisionSelectId: storedStates[0]?.state_id || shallowSupplier.state_id,
            citySelectId: storedCities[0]?.city_id || shallowSupplier.city_id,
            countrySelectId: storedCountries[0]?.township_id || shallowSupplier.country_id,
            // value
            divisionValue: shallowSupplier.sup_state_name,
            countryValue: shallowSupplier.sup_country_name,
            cityValue: shallowSupplier.sup_city_name
        });
    }, [
        countryData,
        cities,
        states,
        storedCountries,
        shallowSupplier
    ]);

    const asyncDispatcher = useCallback(
        async (reqBody: any) => {
            try {
                console.log(reqBody)
                const rest = removeKeys(
                    reqBody,
                    'state_id',
                    'state_name',
                    'city_id',
                    'city_name',
                    'country_id',
                    'country_name'
                );
                console.log("Fdf",{ 
                    ...rest,
                    supplier_division_id: reqBody.state_id,
                    supplier_city_id: reqBody.city_id,
                    supplier_township_id: reqBody.township_id
                })
                const res = await updateSupplier({
                    id,
                    body: { 
                        ...rest,
                        supplier_division_id: reqBody.state_id,
                        supplier_city_id: reqBody.city_id,
                        supplier_country_id: reqBody.country_id
                    },
                });

                const successData = 'data' in res ? res.data : null;
                const { success, message } = successData;

                if (success || isSuccess) {
                    Toast.fire({
                        title: message,
                        icon: 'success',
                    });

                    navigate(`${SUPPLIER.BASE_PATH}`, {
                        state: {
                            reload: true,
                        },
                    });
                }
            } catch (error) {
                console.log('Error', error);
            }
        },
        [updateSupplier, isSuccess, Toast, navigate]
    );

    return (
        <PageWrapper>
            {isLoading && <LoadingSpinner />}
            <CustomForm
                title={supplier.EDIT_TITLE}
                schema={supplierSchema}
                defaultValues={shallowSupplier}
                fields={fields}
                asyncDispatcher={asyncDispatcher}
            />
        </PageWrapper>
    );
};

export default SupplierEditPage;
