import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { UNIT_OF_MEASUREMENT } from '../../utils/constants/unit-of-measurement.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const unitOfMeasurementApi = createApi({
    reducerPath: 'unitOfMeasurementApi',
    tagTypes: ['UnitOfMeasurement'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllUnitOfMeasurements: builder.query<
            [],
            { page?: number; limit?: number, search_key?: string }
        >({
            query: ({ page = 1, limit = 10, search_key }) => {
                const queryParams = {
                    url: UNIT_OF_MEASUREMENT.GET_ALL,
                    params: { page, limit, status: 1, search_key },
                };

                if (search_key) {
                    queryParams.params.search_key = search_key;
                }

                return queryParams;
            },
        }),
        createUnitOfMeasurement: builder.mutation({
            query: (body) => ({
                url: UNIT_OF_MEASUREMENT.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateUnitOfMeasurement: builder.mutation({
            query: ({ id, body }) => ({
                url: `${UNIT_OF_MEASUREMENT.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteUnitOfMeasurement: builder.mutation({
            query: (id) => ({
                url: `${UNIT_OF_MEASUREMENT.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0
});

export const {
    useGetAllUnitOfMeasurementsQuery,
    useCreateUnitOfMeasurementMutation,
    useUpdateUnitOfMeasurementMutation,
    useDeleteUnitOfMeasurementMutation,
} = unitOfMeasurementApi;
