export const WHOLE_SALE_ORDER = {
    BASE_PATH: '/whole-sale-order/sale-order/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    CONVERT_TO_INVOICE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/convert-to-invoice';
    },
    ADD_BUTTON_TITLE: 'Add New Whole Sale Order',
    ADD_PAGE_TITLE: 'Add New Whole Sale Order',
    LIST_PAGE_TITLE: 'Whole Sale Order List',
    EDIT_PAGE_TITLE: 'Edit Whole Sale Order',
    GET_ALL: 'whole-sales/sale-order',
    GET_ONE: 'whole-sales/sale-order/edit/',
    CREATE: 'whole-sales/sale-order',
    UPDATE: 'whole-sales/sale-order/update/',
    DELETE: 'whole-sales/sale-order/delete/',
    APPROVE: 'whole-sales/order-approval/update/',
};
