import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { RETAIL_SALE } from "@/utils/constants/retail-sale/retail-sale.constants";
import { baseQuery, baseQueryWithReauth } from "../auth/auth.api";
export const retailSaleApi = createApi({
    reducerPath: "retailSaleApi",
    tagTypes: ["RetailSale"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllRetailSales: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: RETAIL_SALE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        getRetailSale: builder.query<any, number>({
            query: (id) => {
                return `${RETAIL_SALE.GET_ONE}${id}`;
            },
        }),
        createRetailSale: builder.mutation({
            query: (body) => ({
                url: RETAIL_SALE.CREATE,
                method: "POST",
                body,
            }),
        }),
        updateRetailSale: builder.mutation({
            query: ({ id, body }) => ({
                url: `${RETAIL_SALE.UPDATE}${id}`,
                method: "PATCH",
                body,
            }),
        }),
        deleteRetailSale: builder.mutation({
            query: (id) => ({
                url: `${RETAIL_SALE.DELETE}${id}`,
                method: "DELETE",
            }),
        }),
        getAllRetailSaleOrderCode: builder.query<Record<string, any>, void>({
            query: () => `${RETAIL_SALE.GET_ALL}sale-codes`
        }),
        getNewRetailSaleCode: builder.query<Record<string, any>, void>({
            query: () => `${RETAIL_SALE.GET_ALL}get/new-code`
        })
    }),
});

export const {
    useGetAllRetailSalesQuery,
    useGetRetailSaleQuery,
    useCreateRetailSaleMutation,
    useUpdateRetailSaleMutation,
    useDeleteRetailSaleMutation,
    useGetAllRetailSaleOrderCodeQuery,
    useGetNewRetailSaleCodeQuery
} = retailSaleApi;
