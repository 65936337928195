import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@states/store';
import { useGetAllCountriesQuery, useGetAllStatesQuery } from '@states/common/common.api';
import { addCities } from '@/states/common/common.slice';

const useLocationEffect = () => {
    const [states, setStates] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);

    const { data: countryData, isLoading } = useGetAllCountriesQuery();
    const {
        countries,
        states: storedStates,
        cities: storedCities,
    } = useSelector((state: RootState) => state.common);

    useEffect(() => {
        if (countries.length > 0) {
            const selectedCountries = countryData?.filter((country: any) =>
                countries
                    .map((c: any) => parseInt(c.country_id))
                    .includes(country.country_id)
            );

            const countryStates = selectedCountries?.map((country: any) =>
                country.states.map((s: any) => ({
                    ...s,
                    country_id: country.state_id,
                    country_name: country.country_name,
                }))
            );

            const flatData = countryStates?.flat(1) as any[];
            setStates(flatData || []);
        }
    }, [countries, countryData]);

    useEffect(() => {
        if (storedStates.length > 0) {
            const yStateIds = storedStates.map((item: any) =>
                parseInt(item.state_id)
            );

            const filteredStates = states.filter((s) =>
                yStateIds.includes(s.state_id)
            );

            const stateCities = filteredStates?.map((state) =>
                state.cities?.map((c: any) => ({
                    ...c,
                    country_id: state.country_id,
                    country_name: state.country_name,
                    state_: state.state_id,
                    state_name: state.state_name,
                }))
            );

            const flatCity = stateCities?.flat(1).filter((t) => t !== undefined) || [];
            setCities(flatCity );
        }
    }, [states, countryData, storedStates, storedCities]);

    return { cities, states, isLoading };
};

export default useLocationEffect;