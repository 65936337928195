import { createSlice } from '@reduxjs/toolkit';



export const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, accessToken: null, refreshToken: null ,permissions:null},
    reducers: {
        setCredentials: (state, action) => {
            const { user, accessToken, refreshToken,permissions } = action.payload;
            user && (state.user = user);
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            permissions && (state.permissions = permissions);

            localStorage.setItem('dms-access-token', accessToken);
            localStorage.setItem('dms-refresh-token', refreshToken);
            user && localStorage.setItem('dms-user', JSON.stringify(user));
            permissions && localStorage.setItem('dms-user-permissions', JSON.stringify(permissions));

        },
        logOut: (state) => {
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;
            state.permissions = null;

            localStorage.removeItem('dms-user');
            localStorage.removeItem('dms-access-token');
            localStorage.removeItem('dms-refresh-token');
            localStorage.removeItem('dms-user-permissions');

        },
    },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: any) => state.auth.user;
export const selectCurrentToken = (state: any) => state.auth.accessToken;
export const selectCurrentUserPermissions = (state:any) => state.auth.permissions;