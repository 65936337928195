import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoadingSpinner } from "./components/layouts/LoadingSpinner";
import SupplierPage from "./pages/supplier/SupplierPage";
import SupplierAddPage from "./pages/supplier/SupplierAddPage";
import SupplierEditPage from "./pages/supplier/SupplierEditPage";
import RequireAuth from "./pages/RequireAuth";

const ShopCategoryPage = lazy(
    () => import("./pages/shop-management/shop-category/ShopCategoryPage")
);

const ShopCategoryAddPage = lazy(
    () => import("./pages/shop-management/shop-category/ShopCategoryAddPage")
);

const ShopCategoryEditPage = lazy(
    () => import("./pages/shop-management/shop-category/ShopCategoryEditPage")
);

const ShopPage = lazy(() => import("./pages/shop-management/shop/ShopPage"));
const ShopAddPage = lazy(
    () => import("./pages/shop-management/shop/ShopAddPage")
);
const ShopEditPage = lazy(
    () => import("./pages/shop-management/shop/ShopEditPage")
);

const OfficeUserPage = lazy(
    () => import("./pages/user-management/office-user/OfficeUserPage")
);
const OfficeUserAddPage = lazy(
    () => import("./pages/user-management/office-user/OfficeUserAddPage")
);
const OfficeUserEditPage = lazy(
    () => import("./pages/user-management/office-user/OfficeUserEditPage")
);
const ResetPasswordPage = lazy(
    () => import("./pages/user-management/office-user/ResetPasswordPage")
);
const OfficeUserRolePage = lazy(
    () => import("./pages/user-management/office-user-role/OfficeUserRolePage")
);
const OfficeUserRoleAddPage = lazy(
    () => import("./pages/user-management/office-user-role/OfficeUserRoleAddPage")
);
const CustomerUserPage = lazy(
    () => import("./pages/user-management/customer-user/CustomerUserPage")
);
const CustomerCategoryPage = lazy(
    () => import("./pages/customer/customer-category/CustomerCategoryPage")
);
const CustomerCategoryAddPage = lazy(
    () => import("./pages/customer/customer-category/CustomerCategoryAddPage")
);
const CustomerCategoryEditPage = lazy(
    () => import("./pages/customer/customer-category/CustomerCategoryEditPage")
);
const CustomerPage = lazy(
    () => import("./pages/customer/customer/CustomerPage")
);
const CustomerAddPage = lazy(
    () => import("./pages/customer/customer/CustomerAddPage")
);
const CustomerEditPage = lazy(
    () => import("./pages/customer/customer/CustomerEditPage")
);
const ProductCategoryPage = lazy(
    () => import("./pages/product/product-category/ProductCategoryPage")
);
const ProductCategoryAddPage = lazy(
    () => import("./pages/product/product-category/ProductCategoryAddPage")
);
const ProductCategoryEditPage = lazy(
    () => import("./pages/product/product-category/ProductCategoryEditPage")
);
const BrandPage = lazy(() => import("./pages/product/brand/BrandPage"));
const BrandAddPage = lazy(() => import("./pages/product/brand/BrandAddPage"));
const BrandEditPage = lazy(() => import("./pages/product/brand/BrandEditPage"));
const UnitOfMeasurementPage = lazy(
    () => import("./pages/unit/unit-of-measurement/UnitOfMeasurementPage")
);
const UnitOfMeasurementAddPage = lazy(
    () => import("./pages/unit/unit-of-measurement/UnitOfMeasurementAddPage")
);
const UnitOfMeasurementEditPage = lazy(
    () => import("./pages/unit/unit-of-measurement/UnitOfMeasurementEditPage")
);
const UnitOfConversionPage = lazy(
    () => import("./pages/unit/unit-of-conversion/UnitOfCoversionPage")
);
const UnitOfConversionAddPage = lazy(
    () => import("./pages/unit/unit-of-conversion/UnitOfConversionAddPage")
);
const UnitOfConversionEditPage = lazy(
    () => import("./pages/unit/unit-of-conversion/UnitOfConversionEditPage")
);
const ProductInformationPage = lazy(
    () => import("./pages/product/product-information/ProductInformationPage")
);
const ProductInformationAddPage = lazy(
    () => import("./pages/product/product-information/ProductInformationAddPage")
);
const ProductInformationEditPage = lazy(
    () => import("./pages/product/product-information/ProductInformationEditPage")
);
const UserRoleAssignPage = lazy(
    () => import("./pages/user-management/user-role-assign/UserRoleAssignPage")
);
// const UserRoleAssignAddPage = lazy(
//   () => import("./pages/user-management/user-role-assign/UserRoleAssignAddPage")
// );
const UserRoleAssignAddPage = lazy(
    () =>
        import("./pages/user-management/user-role-assign/v2/UserRoleAssignAddPage")
);
const UserRoleAssignEditPage = lazy(
    () =>
        import("./pages/user-management/user-role-assign/UserRoleAssignEditPage")
);
const WarehousePage = lazy(
    () => import("./pages/warehouse-and-inventory/warehouse/WarehousePage")
);
const WarehouseAddPage = lazy(
    () => import("./pages/warehouse-and-inventory/warehouse/WarehouseAddPage")
);
const WarehouseEditPage = lazy(
    () => import("./pages/warehouse-and-inventory/warehouse/WarehouseEditPage")
);
const SecondarySaleOrderPage = lazy(
    () =>
        import("./pages/whole-sales-order/sales-order/WholeSaleOrderPage")
);
const SecondarySalesOrderAddPage = lazy(
    () =>
        import(
            "./pages/whole-sales-order/sales-order/WholeSalesOrderAddPage"
        )
);
const SecondarySalesOrderEditPage = lazy(
    () =>
        import(
            "./pages/whole-sales-order/sales-order/WholeSalesOrderEditPage"
        )
);
const SecondaryInvoiceAddPage = lazy(
    () => import("./pages/whole-sales-order/invoice/SecondaryInvoiceAddPage")
);
const SecondaryInvoicePage = lazy(
    () => import("./pages/whole-sales-order/invoice/SecondaryInvoicePage")
);
const SecondaryInvoiceEditPage = lazy(
    () => import("./pages/whole-sales-order/invoice/SecondaryInvoiceEditPage")
);
const SecondaryPaymentReceiveAddPage = lazy(
    () =>
        import(
            "./pages/whole-sales-order/payment-receive/SecondaryPaymentReceiveAddPage"
        )
);
const SecondaryPaymentReceivePage = lazy(
    () =>
        import(
            "./pages/whole-sales-order/payment-receive/SecondaryPaymentReceivePage"
        )
);
const StockImportPage = lazy(
    () => import("./pages/warehouse-and-inventory/stock-import/StockImportPage")
);
const StockImportAddPage = lazy(
    () =>
        import("./pages/warehouse-and-inventory/stock-import/StockImportAddPage")
);
const StockImportEditPage = lazy(
    () =>
        import("./pages/warehouse-and-inventory/stock-import/StockImportEditPage")
);
// purchase order module
const PurchaseOrderPage = lazy(
    () => import("./pages/purchase/purchase-order/PurchaseOrderPage")
);
const PurchaseOrderAddPage = lazy(
    () => import("./pages/purchase/purchase-order/PurchaseOrderAddPage")
);
const PurchaseOrderEditPage = lazy(
    () => import("./pages/purchase/purchase-order/PurchaseOrderEditPage")
);

const BillPage = lazy(() => import("./pages/purchase/bill/BillPage"));
const BillAddPage = lazy(() => import("./pages/purchase/bill/BillAddPage"));
const BillEditPage = lazy(() => import("./pages/purchase/bill/BillEditPage"));

const PaymentMadeAddPage = lazy(
    () => import("./pages/purchase/payment-made/PaymentMadeAddPage")
);
const PaymentMadePage = lazy(
    () => import("./pages/purchase/payment-made/PaymentMadePage")
);
//
const StockTransferPage = lazy(
    () =>
        import("./pages/warehouse-and-inventory/stock-transfer/StockTransferPage")
);
const StockTransferAddPage = lazy(
    () =>
        import(
            "./pages/warehouse-and-inventory/stock-transfer/StockTransferAddPage"
        )
);
const StockTransferEditPage = lazy(
    () =>
        import(
            "./pages/warehouse-and-inventory/stock-transfer/StockTransferEditPage"
        )
);
const StockBalancePage = lazy(
    () => import("./pages/warehouse-and-inventory/stock-balance/StockBalancePage")
);
const StockAdjustmentPage = lazy(
    () =>
        import(
            "./pages/warehouse-and-inventory/stock-adjustment/StockAdjustmentPage"
        )
);
const StockAdjustmentAddPage = lazy(
    () =>
        import(
            "./pages/warehouse-and-inventory/stock-adjustment/StockAdjustmentAddPage"
        )
);
const LoginPage = lazy(() => import("./pages/LoginPage"));

const ShopUserAssignPage = lazy(
    () => import("./pages/user-management/shop-user-assign/ShopUserAssignPage")
);

const ShopUserAssignAddPage = lazy(
    () => import("./pages/user-management/shop-user-assign/ShopUserAssignAddPage")
);

const ShopUserAssignEditPage = lazy(
    () =>
        import("./pages/user-management/shop-user-assign/ShopUserAssignEditPage")
);

const RetailSaleOrderHistory = lazy(
    () => import("./pages/retail-sale/RetailSaleOrderHistoryPage")
);

const RetailSaleAddPage = lazy(
    () => import("./pages/retail-sale/RetailSaleAddPage")
);
const RetailSaleEditPage = lazy(
    () => import("./pages/retail-sale/RetailSaleEditPage")
);

const PurchaseReturnPage = lazy(
    () => import("./pages/purchase-return/purchase-return/PurchaseReturnPage")
);
const PurchaseReturnAddPage = lazy(
    () => import("./pages/purchase-return/purchase-return/PurchaseReturnAddPage")
);
const PurchaseReturnEditPage = lazy(
    () => import("./pages/purchase-return/purchase-return/PurchaseReturnEditPage")
);

const PurchaseReturnPaymentReceivePage = lazy(
    () =>
        import(
            "./pages/purchase-return/payment-receive/PurchaseReturnPaymentReceivePage"
        )
);
const PurchaseReturnPaymentReceiveAddPage = lazy(
    () =>
        import(
            "./pages/purchase-return/payment-receive/PurchaseReturnPaymentReceiveAddPage"
        )
);

const ProductCategoryAssignPage = lazy(
    () =>
        import("./pages/product/product-category-assign/ProductCategoryAssignPage")
);
const ProductCategoryAssignAddPage = lazy(
    () =>
        import(
            "./pages/product/product-category-assign/ProductCategoryAssignAddPage"
        )
);

const ProductCategoryAssignEditPage = lazy(
    () =>
        import(
            "./pages/product/product-category-assign/ProductCategoryAssignEditPage"
        )
);

const OfficeUserRoleEditPage = lazy(
    () => import("./pages/user-management/office-user-role/OfficeUserRoleEditPage")
)

const SalesReturnPage = lazy(() => import("./pages/sales-return/sales-return/SalesReturnPage"));
const SaleReturnAddPage = lazy(() => import("./pages/sales-return/sales-return/SalesReturnAddPage"));
const SaleReturnEditPage = lazy(() => import("./pages/sales-return/sales-return/SalesReturnEditPage"));
const MakePaymentPage = lazy(() => import("./pages/sales-return/make-payment/MakePaymentPage"));
const MakePayAddPage = lazy(() => import("./pages/sales-return/make-payment/MakePaymentAddPage"));

const NoPermissionPage = lazy(() => import("./pages/NoPermission"))
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));

const Dashboard = lazy(() => import("./pages/dashboard"));

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <RequireAuth />,
            children: [
                {
                    path: "/no-permission",
                    element: <NoPermissionPage />,
                },
                {
                    path: "/",
                    index: true,
                    element: <div>Landing page</div>,
                },
                {
                    path: "/dashboard",
                    element: <Dashboard />,
                },
                // Shop Category
                {
                    path: "/shop-management/shop-category",
                    element: <ShopCategoryPage />,
                },
                {
                    path: "/shop-management/shop-category/add",
                    element: <ShopCategoryAddPage />,
                },
                {
                    path: "/shop-management/shop-category/:id/edit",
                    element: <ShopCategoryEditPage />,
                },

                // Shop
                {
                    path: "/shop-management/shop",
                    element: <ShopPage />,
                },
                {
                    path: "/shop-management/shop/add",
                    element: <ShopAddPage />,
                },
                {
                    path: "/shop-management/shop/:id/edit",
                    element: <ShopEditPage />,
                },

                // Office User
                {
                    path: "/user-management/office-user",
                    element: <OfficeUserPage />,
                },
                {
                    path: "/user-management/office-user/add",
                    element: <OfficeUserAddPage />,
                },
                {
                    path: "/user-management/office-user/:id/edit",
                    element: <OfficeUserEditPage />,
                },
                {
                    path: "/user-management/office-user/:id/reset-password",
                    element: <ResetPasswordPage />,
                },

                // Office User Role Page
                {
                    path: "/user-management/user-roles",
                    element: <OfficeUserRolePage />,
                },
                {
                    path: "/user-management/user-roles/add",
                    element: <OfficeUserRoleAddPage />,
                },
                {
                    path: "/user-management/user-roles/:id/edit",
                    element: <OfficeUserRoleEditPage />,
                },
                // User Role Assign
                {
                    path: "/user-management/user-role-assign",
                    element: <UserRoleAssignPage />,
                },
                {
                    path: "/user-management/user-role-assign/add",
                    element: <UserRoleAssignAddPage />,
                },
                {
                    path: "/user-management/user-role-assign/:assignId/edit",
                    element: <UserRoleAssignEditPage />,
                },
                //Shop User Assign
                {
                    path: "/user-management/shop-user-assign",
                    element: <ShopUserAssignPage />,
                },

                {
                    path: "/user-management/shop-user-assign/add",
                    element: <ShopUserAssignAddPage />,
                },

                {
                    path: "/user-management/shop-user-assign/:userId/edit",
                    element: <ShopUserAssignEditPage />,
                },

                // Customer Category
                {
                    path: "/customer/customer-category",
                    element: <CustomerCategoryPage />,
                },
                {
                    path: "/customer/customer-category/add",
                    element: <CustomerCategoryAddPage />,
                },
                {
                    path: "/customer/customer-category/:id/edit",
                    element: <CustomerCategoryEditPage />,
                },
                // Customer
                {
                    path: "/customer/customer",
                    element: <CustomerPage />,
                },
                {
                    path: "/customer/customer/add",
                    element: <CustomerAddPage />,
                },
                {
                    path: "/customer/customer/:id/edit",
                    element: <CustomerEditPage />,
                },

                // Supplier
                {
                    path: "/purchase/supplier",
                    element: <SupplierPage />,
                },
                {
                    path: "/purchase/supplier/add",
                    element: <SupplierAddPage />,
                },
                {
                    path: "/purchase/supplier/:id/edit",
                    element: <SupplierEditPage />,
                },
                // // Business Unit
                // {
                //     path: "/customer/business-unit",
                //     element: <BusinessUnitPage />,
                // },
                // {
                //     path: "/customer/business-unit/add",
                //     element: <BusinessUnitAddPage />,
                // },
                // {
                //     path: "/customer/business-unit/:id/edit",
                //     element: <BusinessUnitEditPage />,
                // },
                // Product Category
                {
                    path: "/product/product-category",
                    element: <ProductCategoryPage />,
                },
                {
                    path: "/product/product-category/add",
                    element: <ProductCategoryAddPage />,
                },
                {
                    path: "/product/product-category/:id/edit",
                    element: <ProductCategoryEditPage />,
                },
                // Brand
                {
                    path: "/product/brand",
                    element: <BrandPage />,
                },
                {
                    path: "/product/brand/add",
                    element: <BrandAddPage />,
                },
                {
                    path: "/product/brand/:id/edit",
                    element: <BrandEditPage />,
                },
                // Unit of Measurement
                {
                    path: "/unit/unit-of-measurement",
                    element: <UnitOfMeasurementPage />,
                },
                {
                    path: "/unit/unit-of-measurement/add",
                    element: <UnitOfMeasurementAddPage />,
                },
                {
                    path: "/unit/unit-of-measurement/:id/edit",
                    element: <UnitOfMeasurementEditPage />,
                },
                // Unit of Conversion
                {
                    path: "/unit/unit-of-conversion",
                    element: <UnitOfConversionPage />,
                },
                {
                    path: "/unit/unit-of-conversion/add",
                    element: <UnitOfConversionAddPage />,
                },
                {
                    path: "/unit/unit-of-conversion/:id/edit",
                    element: <UnitOfConversionEditPage />,
                },
                // Product Information
                {
                    path: "/product/product-information",
                    element: <ProductInformationPage />,
                },
                {
                    path: "/product/product-information/add",
                    element: <ProductInformationAddPage />,
                },
                {
                    path: "/product/product-information/:id/edit",
                    element: <ProductInformationEditPage />,
                },

                // Product Category Assign
                {
                    path: "/product/product-category-assign",
                    element: <ProductCategoryAssignPage />,
                },
                {
                    path: "/product/product-category-assign/add",
                    element: <ProductCategoryAssignAddPage />,
                },
                {
                    path: "/product/product-category-assign/:warehouseId/edit",
                    element: <ProductCategoryAssignEditPage />,
                },
                // Warehoue
                {
                    path: "/warehouse-and-inventory/warehouse",
                    element: <WarehousePage />,
                },
                {
                    path: "/warehouse-and-inventory/warehouse/add",
                    element: <WarehouseAddPage />,
                },
                {
                    path: "/warehouse-and-inventory/warehouse/:id/edit",
                    element: <WarehouseEditPage />,
                },
                // Stock Import
                {
                    path: "/warehouse-and-inventory/stock-import",
                    element: <StockImportPage />,
                },
                {
                    path: "/warehouse-and-inventory/stock-import/add",
                    element: <StockImportAddPage />,
                },
                {
                    path: "/warehouse-and-inventory/stock-import/:id/edit/:batch_no",
                    element: <StockImportEditPage />,
                },
                // Stock Transfer
                {
                    path: "/warehouse-and-inventory/stock-transfer",
                    element: <StockTransferPage />,
                },
                {
                    path: "/warehouse-and-inventory/stock-transfer/add",
                    element: <StockTransferAddPage />,
                },
                {
                    path: "/warehouse-and-inventory/stock-transfer/:id/edit",
                    element: <StockTransferEditPage />,
                },
                // Stock Balance
                {
                    path: "/warehouse-and-inventory/stock-balance",
                    element: <StockBalancePage />,
                },
                // Stock Adjustment
                {
                    path: "/warehouse-and-inventory/stock-adjustment",
                    element: <StockAdjustmentPage />,
                },
                {
                    path: "/warehouse-and-inventory/stock-adjustment/add",
                    element: <StockAdjustmentAddPage />,
                },
                // Purchase Order
                {
                    path: "/purchase/purchase-order",
                    element: <PurchaseOrderPage />,
                },
                {
                    path: "/purchase/purchase-order/add",
                    element: <PurchaseOrderAddPage />,
                },
                {
                    path: "/purchase/purchase-order/:id/edit",
                    element: <PurchaseOrderEditPage />,
                },
                {
                    path: "/purchase/purchase-order/:id/edit/convert-to-bill",
                    element: <BillAddPage />,
                },
                // Bill Page
                {
                    path: "/purchase/bill",
                    element: <BillPage />,
                },
                {
                    path: "/purchase/bill/:id/edit/made-payment",
                    element: <PaymentMadeAddPage />,
                },
                {
                    path: "/purchase/bill/:id/edit",
                    element: <BillEditPage />,
                },

                // payment made
                {
                    path: "/purchase/payment-made",
                    element: <PaymentMadePage />,
                },
                // Secondary Sales Order
                {
                    path: "/whole-sale-order/sale-order",
                    element: <SecondarySaleOrderPage />,
                },
                {
                    path: "/whole-sale-order/sale-order/add",
                    element: <SecondarySalesOrderAddPage />,
                },
                {
                    path: "/whole-sale-order/sale-order/:id/edit",
                    element: <SecondarySalesOrderEditPage />,
                },
                {
                    path: "/whole-sale-order/sale-order/:id/edit/convert-to-invoice",
                    element: <SecondaryInvoiceAddPage />,
                },
                // Secondary Invoice
                {
                    path: "/whole-sale-order/invoice",
                    element: <SecondaryInvoicePage />,
                },
                {
                    path: "/whole-sale-order/invoice/:id/edit",
                    element: <SecondaryInvoiceEditPage />,
                },
                // Secondary Payment Receive
                {
                    path: "/whole-sale-order/invoice/:id/edit/make-payment-receive",
                    element: <SecondaryPaymentReceiveAddPage />,
                },
                {
                    path: "/whole-sale-order/payment-receive",
                    element: <SecondaryPaymentReceivePage />,
                },
                // Retail Sale
                {
                    path: "/retail-sale/retail-sale-order-history",
                    element: <RetailSaleOrderHistory />,
                },
                {
                    path: "/retail-sale/retail-sale/add",
                    element: <RetailSaleAddPage />,
                },
                {
                    path: "/retail-sale/retail-sale/:id/",
                    element: <RetailSaleEditPage />,
                },
                //Secondary Sales Return
                {
                    path: "/sales-return",
                    element: <SalesReturnPage />,
                },
                {
                    path: "/sales-return/add",
                    element: <SaleReturnAddPage />,
                },
                {
                    path: "/sales-return/:id/edit",
                    element: <SaleReturnEditPage />,
                },
                {
                    path: "/sales-return/make-payment",
                    element: <MakePaymentPage />,
                },
                {
                    path: "/sales-return/:id/edit/make-payment",
                    element: <MakePayAddPage />,
                },
                // Purchase return
                {
                    path: "/purchase-return/purchase-return",
                    element: <PurchaseReturnPage />,
                },
                {
                    path: "/purchase-return/purchase-return/add",
                    element: <PurchaseReturnAddPage />,
                },
                {
                    path: "/purchase-return/purchase-return/:id/edit",
                    element: <PurchaseReturnEditPage />,
                },

                {
                    path: "/purchase-return/payment-receive",
                    element: <PurchaseReturnPaymentReceivePage />,
                },
                {
                    path: "/purchase-return/purchase-return/:id/edit/make-payment",
                    element: <PurchaseReturnPaymentReceiveAddPage />,
                },
            ],
        },
        {
            path: "/login",
            element: <LoginPage />,
        },
        {
            path: "*",
            element: <NotFoundPage />
        }
    ]);

    return (
        <Suspense fallback={<LoadingSpinner />}>
            <RouterProvider router={router} />
        </Suspense>
    );
};

export default App;
