import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { SHOP } from "@/utils/constants/shop-management/shop.constant";
import { baseQueryWithReauth } from "../auth/auth.api";

export const shopApi = createApi({
    reducerPath: "shopApi",
    tagTypes: ["Shop"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({

        getAllShops: builder.query<
            [],
            { page?: number; limit?: number; shop_name?: string, shop_category_id?: number }
        >({
            query: ({ page = 1, limit = 10, shop_name, shop_category_id }) => {
                const queryParams = {
                    url: "/shops",
                    params: { page, limit, shop_name, shop_category_id },
                };

                if (shop_name) {
                    //@ts-ignore
                    queryParams.params.shop_name = shop_name;
                }

                if (shop_category_id) {
                    queryParams.params.shop_category_id = shop_category_id;
                }

                return queryParams;
            },
        }),

        createShop: builder.mutation({
            query: (body) => ({
                url: "/shops",
                method: "POST",
                body,
            }),
        }),

        updateShop: builder.mutation({
            query: ({ id, body }) => ({
                url: `/shops/${id}`,
                method: "PATCH",
                body,
            }),
        }),

        deleteShop: builder.mutation({
            query: (id) => ({
                url: `/shops/${id}`,
                method: "DELETE",
            }),
        }),

        getNewShopCode: builder.query<Record<string, any>, void>({
            query: () => "/shops/new-code"
        })
    }),
    keepUnusedDataFor: 0,
});

export const {
    useGetAllShopsQuery,
    useCreateShopMutation,
    useUpdateShopMutation,
    useDeleteShopMutation,
    useGetNewShopCodeQuery
} = shopApi;
