import React from 'react';
import Select, { components } from 'react-select';
import { FieldErrors, Controller, Control } from 'react-hook-form';
import { isNumeric } from '../../utils/general/isNumeric';

interface CustomSelectFieldProps {
    name: string;
    options: any[] | undefined;
    label?: string;
    value?: any;
    noLabel?: boolean;
    defaultValue?: any;
    control: Control;
    errors: FieldErrors<any>;
    disabled?: boolean;
    colSpan?: number;
    required?: boolean;
    classNames?: string;
}

const CustomSelectFieldWithSearch: React.FC<CustomSelectFieldProps> = ({
    name,
    options,
    label,
    value,
    noLabel,
    defaultValue,
    control,
    errors,
    disabled,
    colSpan,
    required,
    classNames,
}) => {
    const targetOption = options?.find((option) => option.value == value);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            paddingTop: '0.19rem',
            paddingBottom: '0.19rem',
            borderColor: 'rgb(128,149,187)',
            borderWidth: '0.5px',
        }),
        //@ts-ignore
        option: (base: any, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected
                ? 'rgb(45,120,219)'
                : isFocused
                ? '#e5e7eb'
                : '',
            color: isSelected ? '#ffffff' : '',
            zIndex: 999999,
        }),
    };

    const DropdownIndicator = (props: any) => (
        <components.DropdownIndicator {...props} />
    );

    return (
        <div
            style={{
                gridColumn:
                    colSpan !== 6 ? `auto / span ${colSpan || '2'}` : '1 / -1',
            }}
            className={`flex flex-col p-2 ${classNames}`}
        >
            {!noLabel ? (
                <label
                    className="mb-2 text-gray-700"
                    style={{ textTransform: 'capitalize' }}
                    htmlFor="name"
                >
                    {label || name}
                    {required ? (
                        <span className="ml-[0.15rem] text-red-600 text-md">
                            &#42;
                        </span>
                    ) : null}
                </label>
            ) : null}

            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                        components={{ DropdownIndicator }}
                        menuPortalTarget={document.body}
                        isDisabled={disabled}
                        styles={customStyles}
                        defaultValue={targetOption}
                        inputId={name}
                        value={
                            options?.find((option) => option.value == value) ||
                            null
                        }
                        onBlur={onBlur}
                        onChange={(val) => {
                            if (isNumeric(val.value)) {
                                val.value = parseInt(val.value);
                            }
                            onChange(val.value);
                        }}
                        options={options}
                        placeholder="Type to search..."
                        className="mt-1"
                    />
                )}
            />
            {(
                <div className="mt-2 font-[300] italic text-red-600">
                    {/* @ts-ignore */}
                    {errors[name]?.message}
                </div>
            )}
        </div>
    );
};

export default CustomSelectFieldWithSearch;