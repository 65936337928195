import DeleteButton from '@components/buttons/DeleteButton';
import EditButton from '@components/buttons/EditButton';

export const supplierColumns = (
    editHandler: (supplier: any) => void,
    deleteHandler: (id: string | number) => void,
    canEdit: boolean = true,
    canDelete: boolean = true
) => [
        {
            name: 'Supplier Name',
            selector: (row: any) => (
                <div className=" text-primary-dark">{row.supplier_name}</div>
            ),
            wrap: true
        },
        {
            name: 'Phone Number',
            selector: (row: any) => (
                <div className="text-primary-dark">{row.supplier_phone1}</div>
            ),
        },
        {
            name: 'Address',
            selector: (row: any) => row.supplier_address,
        },
        {
            name: 'Status',
            selector: (row: any) => {
                if (row.status == 1) {
                    return <div className="text-green-800">Active</div>;
                } else {
                    return <div className="text-red-800">Inactive</div>;
                }
            },
        },
        {
            name: 'Action',
            right: 'true',
            selector: (row: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {
                        canEdit &&
                        <EditButton
                            id={row.supplier_id}
                            actionHandler={() => editHandler(row)}
                        />
                    }
                    {
                        canDelete &&
                        <DeleteButton
                            id={row.supplier_id}
                            actionHandler={deleteHandler}
                        />
                    }
                </div>
            ),
        },
    ];
