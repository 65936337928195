import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common/common.slice";
import authReducer from "./auth/auth.slice";
// APIs
import { commonApi } from "./common/common.api";
import { officeUserApi } from "./user-management/office-user.api";
import { officeUserRoleApi } from "./user-management/office-user-role.api";
import { customerCategoryApi } from "./customer/customer-category.api";
import { productCategoryApi } from "./product/product-category.api";
import { shopUserAssignApi } from "./user-management/shop-user-assign.api";
import { customerApi } from "./customer/customer.api";
import { supplierApi } from "./supplier/supplier.api";
import { businessUnitApi } from "./customer/business-unit.api";
import { productTagApi } from "./product/product-tag.api";
import { unitOfMeasurementApi } from "./unit/unit-of-measurement.api";
import { unitOfConversionApi } from "./unit/unit-of-conversion.api";
import { userRoleAssignApi } from "./user-management/user-role-assign.api";
import { brandApi } from "./product/brand.api";
import { productApi } from "./product/product.api";
import { customerUserApi } from "./user-management/customer-user.api";
import { warehouseApi } from "./warehouse-and-inventory/warehouse.api";
import { warehouseUserAssignApi } from "./warehouse-and-inventory/warehouse-user-assign.api";
import { primarySaleOrderApi } from "./primary-sale-order/primary-sale-order.api";
import { primaryInvoiceApi } from "./primary-sale-order/primary-invoice.api";
import { purchaseBillApi } from "./purchase/purchase-bill.api";
import { paymentMadeApi } from "./purchase/payment-made.api";
import { primaryPaymentReceiveApi } from "./primary-sale-order/primary-payment-receive.api";
import { secondarySaleOrderApi } from "./secondary-sale-order/secondary-sale-order.api";
import { secondaryInvoiceApi } from "./secondary-sale-order/secondary-invoice.api";
import { secondaryPaymentReceiveApi } from "./secondary-sale-order/secondary-payment-receive.api";
import { purchaseOrderApi } from "./purchase/purchase-order.api";
import { stockImportApi } from "./warehouse-and-inventory/stock-import.api";
import { stockTransferApi } from "./warehouse-and-inventory/stock-transfer.api";
import { stockAdjustmentApi } from "./warehouse-and-inventory/stock-adjustment.api";
import { stockBalanceApi } from "./warehouse-and-inventory/stock-balance.api";
import { secondarySalesReturnApi } from "./sales-return/sales-return.api";
import { authApi } from "./auth/auth.api";
import { shopCategoryApi } from "./shop-management/shop-category.api";
import { shopApi } from "./shop-management/shop.api";
import { purchaseReturnApi } from "./purchase-return/puchase-return.api";
import { productCategoryAssignApi } from "./product/product-category-assign.api";
import { retailSaleApi } from "./retail-sale/retail-sale.api";

export const store = configureStore({
  reducer: {
    [commonApi.reducerPath]: commonApi.reducer,
    [officeUserApi.reducerPath]: officeUserApi.reducer,
    [officeUserRoleApi.reducerPath]: officeUserRoleApi.reducer,
    [customerCategoryApi.reducerPath]: customerCategoryApi.reducer,
    [shopUserAssignApi.reducerPath]: shopUserAssignApi.reducer,
    [productCategoryApi.reducerPath]: productCategoryApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [supplierApi.reducerPath]: supplierApi.reducer,
    [businessUnitApi.reducerPath]: businessUnitApi.reducer,
    [productTagApi.reducerPath]: productTagApi.reducer,
    [unitOfMeasurementApi.reducerPath]: unitOfMeasurementApi.reducer,
    [unitOfConversionApi.reducerPath]: unitOfConversionApi.reducer,
    [userRoleAssignApi.reducerPath]: userRoleAssignApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerUserApi.reducerPath]: customerUserApi.reducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
    [primarySaleOrderApi.reducerPath]: primarySaleOrderApi.reducer,
    [primaryInvoiceApi.reducerPath]: primaryInvoiceApi.reducer,
    [primaryPaymentReceiveApi.reducerPath]: primaryPaymentReceiveApi.reducer,
    [warehouseUserAssignApi.reducerPath]: warehouseUserAssignApi.reducer,
    [secondarySaleOrderApi.reducerPath]: secondarySaleOrderApi.reducer,
    [secondaryInvoiceApi.reducerPath]: secondaryInvoiceApi.reducer,
    [secondaryPaymentReceiveApi.reducerPath]:
      secondaryPaymentReceiveApi.reducer,
    [purchaseOrderApi.reducerPath]: purchaseOrderApi.reducer,
    [purchaseBillApi.reducerPath]: purchaseBillApi.reducer,
    [paymentMadeApi.reducerPath]: paymentMadeApi.reducer,
    [stockImportApi.reducerPath]: stockImportApi.reducer,
    [stockTransferApi.reducerPath]: stockTransferApi.reducer,
    [stockAdjustmentApi.reducerPath]: stockAdjustmentApi.reducer,
    [stockBalanceApi.reducerPath]: stockBalanceApi.reducer,
    [secondarySalesReturnApi.reducerPath]: secondarySalesReturnApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [shopCategoryApi.reducerPath]: shopCategoryApi.reducer,
    [shopApi.reducerPath]: shopApi.reducer,
    [purchaseReturnApi.reducerPath]: purchaseReturnApi.reducer,
    [productCategoryAssignApi.reducerPath]: productCategoryAssignApi.reducer,
    [retailSaleApi.reducerPath]: retailSaleApi.reducer,
    common: commonReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ 
      serializableCheck: {
        ignoredPaths: ['common.formValues.file'],
        ignoredActions: ['common/addFormValues'],
      }
     }).concat([
      shopUserAssignApi.middleware,
      commonApi.middleware,
      officeUserApi.middleware,
      officeUserRoleApi.middleware,
      customerCategoryApi.middleware,
      productCategoryApi.middleware,
      customerApi.middleware,
      supplierApi.middleware,
      businessUnitApi.middleware,
      productTagApi.middleware,
      unitOfMeasurementApi.middleware,
      unitOfConversionApi.middleware,
      userRoleAssignApi.middleware,
      brandApi.middleware,
      productApi.middleware,
      customerUserApi.middleware,
      warehouseApi.middleware,
      primarySaleOrderApi.middleware,
      primaryInvoiceApi.middleware,
      primaryPaymentReceiveApi.middleware,
      warehouseUserAssignApi.middleware,
      secondarySaleOrderApi.middleware,
      secondaryInvoiceApi.middleware,
      secondaryPaymentReceiveApi.middleware,
      stockImportApi.middleware,
      stockTransferApi.middleware,
      stockAdjustmentApi.middleware,
      stockBalanceApi.middleware,
      secondarySalesReturnApi.middleware,
      purchaseOrderApi.middleware,
      purchaseBillApi.middleware,
      paymentMadeApi.middleware,
      authApi.middleware,
      shopCategoryApi.middleware,
      shopApi.middleware,
      purchaseReturnApi.middleware,
      productCategoryAssignApi.middleware,
      retailSaleApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
