import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TCustomer } from '@type-defs/customer/TCustomer';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';
import { TUser } from '@type-defs/general/TUser';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { baseQueryWithReauth } from '../auth/auth.api';

export const customerUserApi = createApi({
    reducerPath: 'customerUserApi',
    tagTypes: ['CustomerUser'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getCustomerUsers: builder.query<[], { customerId: string | number }>({
            query: ({ customerId }) => {
                return {
                    url: 'customer-user',
                    params: {
                        customerId,
                    },
                };
            },
            providesTags: (result, error, arg) => {
                const { success, data } =
                    result as any as TSuccessResponse<TUser>;

                const { customer, customer_users } = data as any as {
                    customer: TCustomer;
                    customer_users: TUser[];
                };

                if (success && customer && customer_users) {
                    const ids = data.map(({ customer_id }) => ({
                        type: 'CustomerUser' as const,
                        id: customer_id,
                    }));

                    console.log([...ids, { type: 'CustomerUser' }]);

                    return [...ids, { type: 'CustomerUser' }];
                }

                return [{ type: 'CustomerUser' }];
            },
        }),
        getCustomerUser: builder.query<
            [],
            { customerId: string | number; userId: string | number }
        >({
            query: ({ customerId, userId }) =>
                `customer-user/edit/${customerId}/${userId}`,
            providesTags: ['CustomerUser'],
        }),
        createCustomerUser: builder.mutation({
            query: (body) => ({
                url: 'customer-user/create',
                method: 'POST',
                body,
            }),
        }),
        updateCustomerUser: builder.mutation({
            query: ({ customerId, userId, body }) => ({
                url: `customer-user/update/${customerId}/${userId}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error, arg) => {
                console.log(arg, 'arrrrrrr');
                return [{ type: 'CustomerUser', id: arg.userId }];
            },
        }),
        deleteCustomerUser: builder.mutation({
            query: ({ customerId, userId }) => ({
                url: `customer-user/delete/${customerId}/${userId}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0,
});

export const {
    useGetCustomerUsersQuery,
    useGetCustomerUserQuery,
    useCreateCustomerUserMutation,
    useUpdateCustomerUserMutation,
    useDeleteCustomerUserMutation,
} = customerUserApi;
