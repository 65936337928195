import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { PURCHASE_RETURN } from "@/utils/constants/puchase-return/puchase-return.constants";
import { baseQueryWithReauth } from "../auth/auth.api";

export const purchaseReturnApi = createApi({
    reducerPath: "purchaseReturnApi",
    tagTypes: ["PurchaseReturn"],
    baseQuery: baseQueryWithReauth,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getAllPurchaseReturns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                product_name?: string;
            }
        >({
            query: ({ page = 1, limit = 10, product_name }) => {
                const queryParams = {
                    url: PURCHASE_RETURN.GET_ALL,
                    params: { page, limit },
                };

                if (product_name) {
                    //@ts-ignore
                    queryParams.params.product_name = product_name;
                }

                return queryParams;
            },
        }),
        getPurchaseReturn: builder.query<
            any,
            { purchase_return_id: string | number }
        >({
            query: ({ purchase_return_id }) =>
                `${PURCHASE_RETURN.GET_ONE}${purchase_return_id}`,
        }),
        createPurchaseReturn: builder.mutation({
            query: (body) => ({
                url: PURCHASE_RETURN.CREATE,
                method: "POST",
                body,
            }),
        }),
        updatePurchaseReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PURCHASE_RETURN.UPDATE}${id}`,
                method: "PATCH",
                body,
            }),
        }),
        deletePurchaseReturn: builder.mutation({
            query: (id) => ({
                url: `${PURCHASE_RETURN.DELETE}${id}`,
                method: "DELETE",
            }),
        }),
        getNewPurchaseReturnCode: builder.query<Record<string, any>, void>({
            query: () => `${PURCHASE_RETURN.GET_ALL}/new-code`
        }),
        getPurchaseReturnProductInfo: builder.query<
            any,
            { bill_id: string }
        >({
            query: ({ bill_id }) => {
                return `${PURCHASE_RETURN.GET_ALL}/product-info/${bill_id}`;
            },
        }),

        getAllPurchaseReturnMakePayments: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                purchase_return_code?: string;
            }
        >({
            query: ({
                page = 1,
                limit = 10,

                purchase_return_code,
            }) => {
                const queryParams = {
                    url: PURCHASE_RETURN.GET_ALL_MAKE_PAYMENTS,
                    params: { page, limit },
                };

                if (purchase_return_code) {
                    //@ts-ignore
                    queryParams.params.purchase_return_code =
                        purchase_return_code;
                }

                return queryParams;
            },
        }),

        createPurchaseReturnMakePayment: builder.mutation({
            query: ({ purchase_return_id, body }) => ({
                url: `${PURCHASE_RETURN.MAKE_PAYMENT}${purchase_return_id}`,
                method: 'POST',
                body,
            }),
        }),

        deletePurchaseReturnMakePayment: builder.mutation({
            query: ({id, body}) => ({
                url: `${PURCHASE_RETURN.DELETE_MAKE_PAYMENT}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
    }),
    keepUnusedDataFor: 0,
});

export const {
    useGetAllPurchaseReturnsQuery,
    useGetPurchaseReturnQuery,
    useCreatePurchaseReturnMutation,
    useUpdatePurchaseReturnMutation,
    useDeletePurchaseReturnMutation,
    useGetNewPurchaseReturnCodeQuery,
    useLazyGetPurchaseReturnProductInfoQuery,
    useGetAllPurchaseReturnMakePaymentsQuery,
    useCreatePurchaseReturnMakePaymentMutation,
    useDeletePurchaseReturnMakePaymentMutation
} = purchaseReturnApi;
