export const UNIT_OF_CONVERSION = {
    BASE_PATH: '/unit/unit-of-conversion/',
    GET_ALL: '/unit-conversion',
    CREATE: '/unit-conversion',
    EDIT: '/unit-conversion/',
    UPDATE: '/unit-conversion/',
    DELETE: '/unit-conversion/',
    ADD_NEW_TITLE: 'Add New Unit of Conversion',
    GET_BASE_UNIT: '/unit-conversion/get/',
};
