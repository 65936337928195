export const modules = {
    DASHBOARD: "Dashboard",
    USER_MANAGEMENT: "User Management",
    SHOP_MANAGEMENT: "Shop Management",
    UNIT: "Unit",
    PRODUCT: "Product",
    WAREHOUSE_INVENTORY: "Warehouse & Inventory",
    CUSTOMER: "Customer",
    PURCHASE: "Purchsase",
    PURCHASE_RETURN: "Purchase Return",
    RETAIL_SALE: "Retail Sale",
    WHOLE_SALE: "Whole Sale",
    SALE_RETURN: "Sale Return"
} as const;

export const moduleMatchIds = {
    [modules.DASHBOARD]: 1,
    [modules.SHOP_MANAGEMENT]: 2,
    [modules.USER_MANAGEMENT]: 3,
    [modules.UNIT]: 4,
    [modules.PRODUCT]: 5,
    [modules.WAREHOUSE_INVENTORY]: 6,
    [modules.CUSTOMER]: 7,
    [modules.PURCHASE]: 8,
    [modules.PURCHASE_RETURN]: 9,
    [modules.RETAIL_SALE]: 10,
    [modules.WHOLE_SALE]: 11,
    [modules.SALE_RETURN]: 12
}

export const subModules = {
    SHOP_CATEGORY: "Shop Category",
    SHOP: "Shop",
    USER: "User",
    USER_ROLE: "User Role",
    SHOP_USER_ASSIGN: "Shop User Assign",
    USER_ROLE_ASSIGN: "User Role Assign",
    UNIT_OF_CONVERSION: "Unit Of Conversion",
    UNIT_OF_MEASUREMENT: "Unit Of Measurement",
    PRODUCT_INFORMATION: "Product Information",
    BRAND: "Brand",
    PRODUCT_CATEGORY: "Product Category",
    PRODUCT_CATEGORY_ASSIGN: "Product Category Assign",
    WAREHOUSE: "Warehouse",
    STOCK_IMPORT: "Stock Import",
    STOCK_TRANSFER: "Stock Transfer",
    STOCK_BALANCE: "Stock Balance",
    STOCK_ADJUSTMENT: "Stock Adjustment",
    CUSTOMER_CATEGORY: "Customer Category",
    CUSTOMER: "Customer",
    SUPPLIER: "Supplier",
    PURCHASE_ORDER: "Purchase Order",
    BILL: "Bill",
    PAYMENT_MADE: "Payment Made",
    PURCHASE_RETURN: "Purchase Return",
    PURCHASE_RETURN_PAYMENT_RECEIVE: "Purchase Return Payment Receive",
    RETAIL_SALE_ORDER:"Retail Sale Order",
    RETAIL_SALE_ORDER_HISTORY: "Retail Sale Order History",
    WHOLE_SALE: "Whole Sale",
    INVOICE: "Invoice",
    WHOLE_SALE_PAYMENT_RECEIVE: "Whole sale Payment Receive",
    SALE_RETURN: "Sale Return",
    MAKE_PAYMENT: "Make Payment"
} as const;

export const subModulesMatchIds = {
    [subModules.SHOP_CATEGORY]: 1,
    [subModules.SHOP]: 2,
    [subModules.USER]: 3,
    [subModules.USER_ROLE]: 32,
    [subModules.SHOP_USER_ASSIGN]: 4,
    [subModules.USER_ROLE_ASSIGN]: 5,
    [subModules.UNIT_OF_CONVERSION]: 6,
    [subModules.UNIT_OF_MEASUREMENT]: 7,
    [subModules.PRODUCT_INFORMATION]: 8,
    [subModules.BRAND]: 9,
    [subModules.PRODUCT_CATEGORY]: 10,
    [subModules.PRODUCT_CATEGORY_ASSIGN]: 11,
    [subModules.WAREHOUSE]: 12,
    [subModules.STOCK_IMPORT]: 13,
    [subModules.STOCK_TRANSFER]: 14,
    [subModules.STOCK_BALANCE]: 15,
    [subModules.STOCK_ADJUSTMENT]: 16,
    [subModules.CUSTOMER]: 17,
    [subModules.CUSTOMER_CATEGORY]: 18,
    [subModules.SUPPLIER]: 19,
    [subModules.PURCHASE_ORDER]: 20,
    [subModules.BILL]: 21,
    [subModules.PAYMENT_MADE]: 22,
    [subModules.PURCHASE_RETURN]: 23,
    [subModules.PURCHASE_RETURN_PAYMENT_RECEIVE]: 24,
    [subModules.RETAIL_SALE_ORDER]: 25,
    [subModules.RETAIL_SALE_ORDER_HISTORY]: 26,
    [subModules.WHOLE_SALE]: 27,
    [subModules.INVOICE]: 28,
    [subModules.WHOLE_SALE_PAYMENT_RECEIVE]: 29,
    [subModules.SALE_RETURN]: 30,
    [subModules.MAKE_PAYMENT]: 31
}

export const actions = {
    VIEW: "view",
    CREATE: "create",
    EDIT: "edit",
    DELETE: "delete"
} as const;