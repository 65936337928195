import React, { useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import PageWrapper from "@components/layouts/PageWrapper";
import Search from "@components/layouts/Search";
import { useLocation, useNavigate } from "react-router-dom";
import { supplierColumns } from "@helpers/columns/supplier/supplier.columns";
import {
    useDeleteSupplierMutation,
    useGetAllSuppliersQuery,
} from "@states/supplier/supplier.api";
import usePagination from "@hooks/usePagination";
import useSearch from "@hooks/useSearch";
import { TCustomer } from "@type-defs/customer/TCustomer";
import TableSkeleton from "@components/layouts/TableSkeleton";
import { DeletePopup } from "@helpers/popups/DeletePopup";
import { TDeleteSuccesResponse } from "@type-defs/general/TDeleteSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import CustomerFilter from "@components/filters/CustomerFilter";
import NoRecord from "@components/layouts/NoRecord";
import { SUPPLIER } from "@utils/constants/supplier.constant";
import { useHasPermission } from "../../hooks/useHasPermission";
import { extraRoute } from "../../utils/constants/extra-route.constants";

const SupplierPage = ({ handleErrors }: any) => {
    const filterRef = useRef({
        customer_type_id: undefined,
        customer_category_id: undefined,
    });
    const { page, limit, handlePageChange, handlePerRowsChange } =
        usePagination();
    const { search, setSearch } = useSearch();
    const navigate = useNavigate();
    const location = useLocation();

    const canView = useHasPermission("Supplier", "view");
    const canEdit = useHasPermission("Supplier", "edit");
    const canDelete = useHasPermission("Supplier", "delete");
    const canCreate = useHasPermission("Supplier", "create");

    useEffect(() => {
        if (!canView) {
            navigate(extraRoute.NO_PERMISSION);
        }
    }, []);

    const {
        data,
        isFetching,
        error: getAllError,
        refetch,
    } = useGetAllSuppliersQuery({
        page,
        limit,
        // customer_first_name: search,
        ...filterRef.current,
    });
    const [deleteCustomer, { isError, error: deleteError, isLoading }] =
        useDeleteSupplierMutation();

    if (handleErrors) {
        handleErrors(deleteError, isError, getAllError);
    }

    useEffect(() => {
        if (location.state?.reload) {
            refetch();
        }
    }, [location.state?.reload]);

    const editHandler = (supplier: any) => {
        navigate(
            `${SUPPLIER.BASE_PATH}${supplier.supplier_id}/edit`,
            {
                state: {
                    supplier,
                },
            }
        );
    };

    const deleteHandler = async (id: number | string) => {
        try {
            const { isConfirmed } = await DeletePopup.fire();

            if (isConfirmed) {
                const res = await deleteCustomer(id);

                const successData = "data" in res ? res.data : null;
                const { success, message } = successData as TDeleteSuccesResponse;

                if (success) {
                    Toast.fire({
                        icon: "success",
                        title: message,
                    });

                    refetch();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Search setSearch={setSearch} />
            <PageWrapper
                canCreate={canCreate}
                addPath={`${SUPPLIER.BASE_PATH}add`}
                addButtonTitle={SUPPLIER.ADD_NEW_TITLE}
                title={SUPPLIER.LIST_TITLE}
            >
                {/* @ts-ignore */}

                <DataTable
                    responsive
                    striped
                    pagination
                    paginationServer
                    //@ts-ignore
                    paginationTotalRows={data?.paginator?.totalItems}
                    progressPending={isFetching}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noDataComponent={<NoRecord />}
                    progressComponent={<TableSkeleton row={10} col={7} />}
                    //@ts-ignore
                    columns={supplierColumns(editHandler, deleteHandler, canEdit, canDelete)}
                    //@ts-ignore
                    data={data?.data}
                />
            </PageWrapper>
        </React.Fragment>
    );
};

export default SupplierPage;
