import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { STOCK_IMPORT } from '@utils/constants/warehouse-and-inventory/stock-import.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const stockImportApi = createApi({
    reducerPath: 'stockImportApi',
    tagTypes: ['StockImport'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllStockImports: builder.query<
            [],
            { page?: number; limit?: number }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: STOCK_IMPORT.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        getStockImport: builder.query<any, { id: string | number, batch_no: number }>({
            query: ({ id, batch_no }) => STOCK_IMPORT.GET_ONE + id + `?batch_no=${batch_no}`,
        }),
        createStockImport: builder.mutation({
            query: (body) => ({
                url: STOCK_IMPORT.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateStockImport: builder.mutation({
            query: ({ id, body }) => ({
                url: `${STOCK_IMPORT.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteStockImport: builder.mutation({
            query: (id) => ({
                url: `${STOCK_IMPORT.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllStockImportsQuery,
    useGetStockImportQuery,
    useCreateStockImportMutation,
    useUpdateStockImportMutation,
    useDeleteStockImportMutation,
} = stockImportApi;
