import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { WHOLE_PAYMENT_RECEIVE } from '@/utils/constants/whole-sale-payment-receive.constants';
import { baseQuery, baseQueryWithReauth } from '@states/auth/auth.api';

export const secondaryPaymentReceiveApi = createApi({
    reducerPath: 'secondaryPaymentReceiveApi',
    tagTypes: ['SecondaryPaymentReceive'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllSecondaryPaymentReceives: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: WHOLE_PAYMENT_RECEIVE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        createSecondaryPaymentReceive: builder.mutation({
            query: (body) => ({
                url: WHOLE_PAYMENT_RECEIVE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deleteSecondaryPaymentReceive: builder.mutation({
            query: ({ id, body }) => ({
                url: `${WHOLE_PAYMENT_RECEIVE.DELETE}${id}`,
                method: 'DELETE',
                body
            }),
        }),
    }),
});

export const {
    useGetAllSecondaryPaymentReceivesQuery,
    useCreateSecondaryPaymentReceiveMutation,
    useDeleteSecondaryPaymentReceiveMutation,
} = secondaryPaymentReceiveApi;
