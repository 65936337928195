import { useDidMountEffect } from "@hooks/useDidMountEffect";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import "animate.css";
import { mainModuleType } from "../../../services/main-modules";
import AuthPermission from "../../../types/general/TAuthPermission";

interface SiderbarItemProps {
    mainModule: mainModuleType[number];
    userPermissions: AuthPermission[],
    routedPath: string;
    setRoutedPath: Dispatch<SetStateAction<string>>;
}

const SidebarItem: React.FC<SiderbarItemProps> = ({
    mainModule,
    routedPath,
    userPermissions,
    setRoutedPath,
}) => {
    const isRoutedPath = `/${routedPath.split("/")[1]}` === mainModule.path;
    const [isRouted, setIsRouted] = useState(isRoutedPath);

    const handleItemClick = (path: string) => {
        if (path === routedPath) {
            // close menu item
            setRoutedPath("");

            return;
        }
        setRoutedPath(path);
    };

    const hasSubmodulePermission = useCallback((subModuleMatchId: number) => {
        return userPermissions.some(up => up.sub_module_match_id === subModuleMatchId && up.actions.includes("view"));
    }, []);

    useDidMountEffect(() => {
        setIsRouted(isRoutedPath);
    }, [routedPath]);

    return (
        <div className="mb-[0.7rem] unselectable">
            <div
                className={`flex items-center px-2 py-[0.7rem] transition-colors duration-200 rounded justify-between ${isRouted ? "bg-primary text-white" : "text-gray-500"
                    }`}
            >
                <div className="flex items-center">
                    {/* <span className="mr-2">
                        {mainModule.renderIcon(isRouted)}
                    </span> */}

                    {mainModule.subModules ? (
                        <>
                            <span>{mainModule.name}</span>
                        </>
                    ) : (
                        <NavLink
                            to={`${mainModule.path}`}
                            onClick={() => handleItemClick(mainModule.path)}
                        >
                            {mainModule.name}
                        </NavLink>
                    )}
                </div>
                {mainModule.subModules ? (
                    <>
                        {isRouted ? (
                            <svg
                                className="cursor-pointer"
                                onClick={() => handleItemClick(mainModule.path)}
                                xmlns="http://www.w3.org/2000/svg"
                                height="20px"
                                viewBox="0 -960 960 960"
                                width="20px"
                                fill="#ffffff"
                            >
                                <path d="m357-384 123-123 123 123 57-56-180-180-180 180 57 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                            </svg>
                        ) : (
                            <svg
                                className="cursor-pointer"
                                onClick={() => handleItemClick(mainModule.path)}
                                xmlns="http://www.w3.org/2000/svg"
                                height="20px"
                                viewBox="0 -960 960 960"
                                width="20px"
                                fill="#6b7280"
                            >
                                <path d="m480-340 180-180-57-56-123 123-123-123-57 56 180 180Zm0 260q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                            </svg>
                        )}
                    </>
                ) : null}
            </div>
            {/* {isRouted && ( */}
            <ul
                className={`ml-6 list-outside list-disc pl-5 transition-[max-height] overflow-y-hidden duration-500 ${isRouted ? "max-h-60" : "max-h-0"
                    }`}
            >
                {mainModule.subModules
                    ? mainModule.subModules.map((subModule) => {
                        // const sub = userPermissions.permissions.find(
                        //     (permission: any) =>
                        //         permission.app_module?.app_module_name
                        //             .trim()
                        //             .toLowerCase() === subModule.name.trim().toLowerCase()
                        // );
                        const sub = hasSubmodulePermission(subModule.match_id);

                        // Check if `sub` exists and if `sub.actions` includes "Read"
                        // if (sub && sub.actions.includes("Read")) {
                        if ( sub ) {
                            return (
                                <li
                                    key={subModule.id || subModule.id+subModule.match_id}
                                    className="py-1 my-2 last:mb-0"
                                >
                                    <NavLink
                                        className={({ isActive }) => {
                                            let className =
                                                "text-[0.85rem] 2xl:text-[0.89rem] hover:text-primary transition-colors duration-200";

                                            if (isActive) {
                                                className += " text-primary";
                                            }

                                            return className;
                                        }}
                                        to={`${mainModule.path}${subModule.path}`}
                                    >
                                        {subModule.name}
                                    </NavLink>
                                </li>
                            );
                        }

                        // If no permission is found, return null
                        return null;
                    })
                    : null}
            </ul>
            {/* )} */}
        </div>
    );
};

export default SidebarItem;
